const initialState = {
	selected: null,
	form: {},
	validation: null,
	isLoading: false,
	message: null,
	list: {}
};


export default function CRUDReducer(state = initialState, action)
{
	if(action.type === "CRUD_LOADING")
		return {...state, isLoading: true, validation: null, message: null};

	else if(action.type === "CRUD_ERROR")
		return {...state, isLoading: false, validation: null, message: {text: action.payload, type: "danger"}};

	else if(action.type === "CRUD_SET_LIST")
		return {
			...state,
			isLoading: false,
			list: {
				...state.list,
				[action.payload.entityTypeName]: action.payload.list || {data: []}
			}
		};

	else if(action.type === "CRUD_UPDATE_FORM_FIELD") {
		const form = action.overwrite ? action.payload : {...state.form, ...action.payload};
		return {...state, isLoading: false, form};

	}else if(action.type === "CRUD_SELECT_ENTITY")
		return {...state, isLoading: false, message: null, selected: action.payload};

	else if(action.type === "CRUD_SET_FORM")
		return {...state, isLoading: false, message: null, validation: null, form: action.payload};

	else if(action.type === "CRUD_VALIDATION_ERROR")
		return {...state, isLoading: false, message: null, validation: action.payload};

	else if(action.type === "CRUD_FORM_SAVED")
		return {...state, isLoading: false, message: {text: "Changes have been saved successfully", type: "success"}, validation: null, form: action.payload.clear ? {} : state.form};

	else if(action.type === "CRUD_DELETE_ENTITY") {
		const payload = action.payload;
		let list = state.list;
		if(payload.entityTypeName) {
			list[payload.entityTypeName].data.splice(payload.index, 1);
		}
		return {...state, isLoading: false, message: {text: "Changes have been saved successfully", type: "success"}, list};
	}

	return state
}