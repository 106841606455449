const initialState = {
	loading: false,
	error: null,
	data: {}
};

export default function reducer(state = initialState, action)
{
	if(action.type === "REPORTS_LOADING")
		return {...state, isLoading: true, error: null};

	else if(action.type === "REPORTS_SET_REPORT")
		return {...state, isLoading: true, error: null, data: {...state.data, [action.payload.type]: action.payload.data}};

	else if(action.type === "REPORTS_ERROR")
		return {...state, isLoading: true, error: action.payload};

	return state
}