import React from "react";
import {Redirect, Route} from "react-router-dom";
import Accounting from ".";
import SalesInvoice from "./Sales/Invoice";

export default {
	reducers: {},
	routes: [
		<Route key="maintenance" exact path="/maintenance" component={() => <Redirect to="/maintenance/reports/overview" />} />,
		<Route key="accounting" exact path="/:app/accounting/:action" component={(props) => <Accounting key={props.location.pathname+props.location.search} {...props}/>} />,
		<Route key="sales_invoice" exact path="/sales/invoice" component={(props) => <SalesInvoice key={props.location.pathname+props.location.search} {...props}/>} />,
	],
	i18n: {
		en: {},
		ar: {
			"Most Active Suppliers": "أكثر الموردين نشاطاً",
			"Purchasing Orders per Supplier": "أوامر الشراء حسب المورد",
			"Purchasing Orders": "أوامر الشراء",
			"Inventory Locations": "مواقع التخزين",
			"Suppliers": "الموردين",
			"Active Suppliers": "موردين نشطين",
			"Inactive Suppliers": "موردين غير نشطين",
			"Suppliers Payments": "طرق الدفع",
			"Amount Paid per Supplier": "المبلغ المدفوع بحسب المورد",

			"Inventory Items": "الأصناف",
			"Inactive Items": "الأصناف الغير فعالة",
			"Active Items": "الأصناف الفعالة",
			"Items per Category": "الأصناف حسب التصنيف",
			"Items Levels": "مستويات الأصناف",
			"Most Purchased Items": "أكثر الأصناف شراءً",
			"Top Items Cost": "أكثر المنتجات تكلفة",
			"Top Items Material Cost": "أكثر الأصناف في تكلفة الخامات",
			"Items per Tax Type": "الأصناف حسب نوع الضريبة",
			"Purchasing Orders per Location": "أوامر الشراء حسب الموقع",
			"Items Price": "الأصناف حسب السعر",
			"Items per Measuring Unit": "الأصناف حسب وحدة القياس",
			"cash_only": "كاش فقط",
			"due_15th_Of_the_Following_Month": "دفع خلال ١٥ يوم",
			"due_By_End_Of_The_Following_Month": "دفع قبل نهاية الشهر",
			"payment_due_within_10_days": "دفع خلال ١٠ ايام",
			"prepaid": "الدفع مقدم",

			"Locations Total": "عدد مواقع الأصول",
			"Stocks Total": "عدد الأصول الثابتة",
			"Stock Per Class": "عدد الأصول حسب الفئة",
			"Inactive Stocks": "أصول غير نشطة",
			"Active Stocks": "أصول نشطة",
			"Stock Per Date": "الأصول حسب التاريخ",

			"Estimates Total": "عدد التسعيرات",
			"Leads Total": "عدد العملاء المحتملين",
			"Tasks Total": "عدد المهام",
			"Opportunities Total": "عدد الفرص",
			"Latest Projects": "أخر المشاريع",

			"Requests Total": "عدد الطلبات",
			"Requests per Item": "الطلبات بحسب الصنف",
			"Requests per Date": "الطلبات بحسب التاريخ",
			"Requests per Customer": "الطلبات بحسب العميل",
		}
	},
	navigation: {
		menu: [
			{
				id: "purchases",
				appID: "accounting",
				order: 3,
				name: "Purchases Administration",
				icon: "icon-menu-05",
				link: "/purchases/reports/overview",
				pages: [
					{ name: "نظرة عامة على المشتريات", link: "/purchases/reports/overview", className: "overview", permission: "5657"},
					{
						name: "عمليات الشراء",
						isGroup: true,
						pages: [
							{ name: "فاتورة مشتريات مباشرة", link: "/purchases/accounting/05", permission: "5637"},
							{ name: "إستلام مخزني فردي", link: "/purchases/accounting/04", permission: "5636"},
							{ name: "استلامات مخزنية متعددة", link: "/purchases/accounting/21", permission: "5656"},
							{ name: "الاستعلام عن الاستلامات المخزنية", link: "/purchases/accounting/19", permission: "5643"},
							{ name: "إدخال أمر الشراء", link: "/purchases/accounting/new_order", permission: "5635"},
							{ name: "إستلام أوامر الشراء", link: "/purchases/accounting/03", permission: "5658"},
						]
					},
					{
						name: "الموردين",
						isGroup: true,
						pages: [
							{ name: "فواتير الموردين", link: "/purchases/accounting/07", permission: "5677"},
							{ name: "مدفوعات الموردين", link: "/purchases/accounting/06", permission: "5639"},
							{ name: "تخصيصات الموردين", link: "/purchases/accounting/09", permission: "5655"},
							{ name: "الاستعلام عن تخصيصات الموردين", link: "/purchases/accounting/12", permission: "5640"},
							{ name: "عقود الموردين", link: "/purchases/accounting/15", permission: "5644"},
							{ name: "الموردين", link: "/purchases/accounting/14", permission: "5634"},
							{ name: "تقرير بونص الموردين", link: "/purchases/accounting/23", permission: "5650"},
							{ name: "حساب المورد", link: "/purchases/accounting/25", permission: "5651"},
						]
					},
					{
						name: "مردودات وإشعارات الموردين",
						isGroup: true,
						pages: [
							{ name: "مردودات الموردين", link: "/purchases/accounting/08", permission: "5638"},
							{ name: "إشعار مدين للمورد", link: "/purchases/accounting/16", permission: "5647"},
							{ name: "إشعار دائن للمورد", link: "/purchases/accounting/17", permission: "5646"},

							{ name: "فاتورة تعاقد", link: "/purchases/accounting/24", permission: "5652"},
							{ name: "مردود فاتورة تعاقد", link: "/purchases/accounting/22", permission: "5659"},
							{ name: "مردودات مشتريات سابقة", link: "/purchases/accounting/31", permission: "5667"},
							{ name: "مردود بضاعة غير مستلمة", link: "/purchases/accounting/32", permission: "5667"},
						]
					},
					{
						name: "التقارير والإستعلامات",
						isGroup: true,
						pages: [
							{ name: "الاستعلام عن فواتير المشتريات", link: "/purchases/accounting/20", permission: "5649"},
							{ name: "الإستعلام عن أوامر الشراء", link: "/purchases/accounting/orders_search", permission: "5642"},
							{ name: "الإستعلام عن حركات المورد", link: "/purchases/accounting/11", permission: "5654"},
							{ name: "الاستعلام عن متبقيات الموردين", link: "/purchases/accounting/18", permission: "5648"},
							{ name: "تقرير تضارب اسعار الشراء", link: "/purchases/accounting/26", permission: "5660"},
							{ name: "تقارير المشتريات", link: "/purchases/accounting/13", permission: "5888"},
							{ name: "مراجعة فواتير المشتريات", link: "/purchases/accounting/27", permission: "5637"},
							{ name: "مراجعة اوامر الشراء", link: "/purchases/accounting/28", permission: "5654"},
							{ name: "مراجعة مردودات الموردين", link: "/purchases/accounting/29", permission: "5654"},
							{ name: "مراجعة الاستلامات المخزنية", link: "/purchases/accounting/30", permission: "5654"},
						]
					},
					{
						name: "الإعدادات",
						isGroup: true,
						pages: [
							{ name: "إعدادات الشركة", link: "/setting/accounting/1", permission: "513"},
							{ name: "إعدادات المستخدمين", link: "/setting/accounting/2", permission: "515"},
							{ name: "إعدادات الوصول", link: "/setting/accounting/3", permission: "514"},
							{ name: "تصنيفات الاتصالات", link: "/setting/accounting/16", permission: "526"},
							{ name: "القيود الملغاه", link: "/setting/accounting/28", permission: "769"},
							{ name: "إلغاء حركة", link: "/setting/accounting/17", permission: "769"},
							{ name: "عرض أو طباعة الحركات", link: "/setting/accounting/18", permission: "771"},
							{ name: "أرفاق المستندات", link: "/setting/accounting/19", permission: "772"},
							{ name: "تاريخ الحركات", link: "/setting/accounting/30", permission: "527"},
						]
					},
				]
			},
			{
				id: "inventory",
				appID: "accounting",
				order: 4,
				name: "Inventory Administration",
				icon: "icon-menu-04",
				link: "/inventory/reports/overview",
				pages: [
					{ name: "نظرة عامة على المخازن", link: "/inventory/reports/overview", className: "overview", permission: "8213"},
					{
						name: "إدارة الأصناف المخزنية",
						isGroup: true,
						pages: [
							{ name: "الأصناف المخزنية", link: "/inventory/accounting/07", permission: "7937"},
							{ name: "الإستعلام عن الأصناف المخزنية", link: "/inventory/accounting/37", permission: "7937"},
							{ name: "تصنيفات الاصناف المخزنية", link: "/inventory/accounting/10", permission: "7939"},
							{ name: "أنواع الاصناف المخزنية", link: "/inventory/accounting/32", permission: "8211"},
							{ name: "الخصائص الفنية", link: "/inventory/accounting/31", permission: "8212"},
							{ name: "عمل تحويل مخزني", link: "/inventory/accounting/02", permission: "8196"},
							{ name: "حركة الصنف المخزني", link: "/inventory/accounting/04", permission: "8194"},
							{ name: "حالة الأصناف المخزنية", link: "/inventory/accounting/05", permission: "8452"},
							{ name: "حالة الأصناف بحسب الفرع", link: "/inventory/accounting/29", permission: "8455"},
							{ name: "تكلفة المشتريات", link: "/inventory/accounting/17", permission: "8453"},
							{ name: "الاصناف الغير نشطه", link: "/inventory/accounting/35", permission: "8460"},
						]
					},
					{
						name: "الأكواد والطلبات",
						isGroup: true,
						pages: [
							{ name: "أكواد الاصناف المخزنية", link: "/inventory/accounting/08", permission: "8195"},
							{ name: "أكواد الأدوات البيعية", link: "/inventory/accounting/09", permission: "7938"},
							{ name: "طلبات البضاعة للفروع", link: "/inventory/accounting/21", permission: "8198"},
							{ name: "طلب بضاعة جديدة للفرع", link: "/inventory/accounting/22", permission: "8199"},
							{ name: "إضافة أمانات العملاء", link: "/inventory/accounting/23", permission: "8200"},
							{ name: "إستعلام أمانات العملاء", link: "/inventory/accounting/27", permission: "8456"},
						]
					},
					{
						name: "التحكم والإدارة",
						isGroup: true,
						pages: [
							{ name: "فروع الشركة", link: "/inventory/accounting/11", permission: "7937"},
							{ name: "مستويات إعادة الطلب", link: "/inventory/accounting/13", permission: "8449"},
							{ name: "عمليات الجرد للفروع", link: "/inventory/accounting/18", permission: "8201"},
							{ name: "وحدات القياس", link: "/inventory/accounting/12", permission: "7940"},
							{ name: "أسعار البيع للصنف المخزني", link: "/inventory/accounting/14", permission: "2818"},
							{ name: "أسعار الشراء للصنف المخزني", link: "/inventory/accounting/15", permission: "5377"},
							{ name: "الشركات المصنعه", link: "/inventory/accounting/19", permission: "8454"},
							{ name: "سندات إعدام بضاعة", link: "/inventory/accounting/25", permission: "8457"},
							{ name: "سند تعديل تكلفة", link: "/inventory/accounting/36", permission: "15882"},
						]
					},
					{
						name: "القيود والإستعلامات",
						isGroup: true,
						pages: [
							{ name: "قيود تعديل الاصناف المخزنية", link: "/inventory/accounting/03", permission: "8197"},
							{ name: "الإستعلام عن التحويلات المخزنية", link: "/inventory/accounting/20", permission: "8202"},
							{ name: "إستعلام التعديلات المخزنية", link: "/inventory/accounting/26", permission: "8204"},
							{ name: "سجل الدخول والخروج", link: "/inventory/accounting/24", permission: "8203"},
							{ name: "تقارير المخازن", link: "/inventory/accounting/06", permission: "8450"},
							{ name: " تقرير الرواكد", link: "/inventory/accounting/30", permission: "8458"},
							{ name: "مراجعة التحويلات المخزنية", link: "/inventory/accounting/33", permission: "8202"},
							{ name: "إجمالي الاصناف بالمخازن", link: "/inventory/accounting/34", permission: "8455"},
						]
					},
				]
			},
			{
				id: "finance",
				appID: "accounting",
				order: 5,
				name: "Finance Administration",
				icon: "icon-menu-03",
				link: "/finance/reports/overview",
				pages: [
					{ name: "نظرة عامة على المالية", link: "/finance/reports/overview", className: "overview", permission: "15886"},
					{
						name: "القيود والحركات",
						isGroup: true,
						pages: [
							{ name: "إدخال قيود اليومية العامة", link: "/finance/accounting/04", permission: "15880"},
							{ name: "إيرادات/مصروفات المستحقة", link: "/finance/accounting/07", permission: "15884"},
							{ name: "ميزان المراجعة", link: "/finance/accounting/12", permission: "16133"},
							{ name: "القائمة المختصرة للميزانية", link: "/finance/accounting/13", permission: "16134"},
							{ name: "قائمة الأرباح و الخسائر", link: "/finance/accounting/14", permission: "16135"},
							{ name: "الإدخالات السريعة", link: "/finance/accounting/18", permission: "15621"},
							{ name: "حسابات الأستاذ العام", link: "/finance/accounting/22", permission: "15618"},
							{ name: "مجموعات حسابات الأستاذ", link: "/finance/accounting/23", permission: "15619"},
							{ name: "تصنيفات حساب الأستاذ", link: "/finance/accounting/24", permission: "15620"},
							{ name: "إغلاق حسابات الاستاذ", link: "/finance/accounting/25", permission: "15630"},
							{ name: "إدخال الموازنات", link: "/finance/accounting/05", permission: "15881"},
							{ name: "الكلمات الدلالية للحساب", link: "/finance/accounting/19", permission: "15627"},
							{ name: "المصروفات والايرادات المتعددة", link: "/finance/accounting/40", permission: "15641"},
							{ name: "الاستعلام عن المصروفات متعددة", link: "/finance/accounting/41", permission: "15643"},
						]
					},
					{
						name: "إدارة الحسابات والتحويلات",
						isGroup: true,
						pages: [
							{ name: "إدخال سند صرف", link: "/finance/accounting/01", permission: "15876"},
							{ name: "إدخال سند قبض", link: "/finance/accounting/02", permission: "15877"},
							// { name: "سند قبض الحسابات", link: "/finance/accounting/34", permission: "123456"},
							{ name: "التحويل بين الحسابات المالية", link: "/finance/accounting/03", permission: "15878"},
							{ name: "تسوية الحسابات المالية", link: "/finance/accounting/06", permission: "15879"},
							{ name: "الحسابات المالية", link: "/finance/accounting/17", permission: "15623"},
							{ name: "نسب خصومات الموظفين", link: "/finance/accounting/35", permission: "15632"},
							{ name: "إعتماد إستحقاق الرواتب", link: "/finance/accounting/44", permission: "A15633"},
							{ name: "إعتماد وتحويل الرواتب", link: "/finance/accounting/45", permission: "F15633"},
							{ name: "إعتماد دفع الرواتب", link: "/finance/accounting/46", permission: "Y15633"},
							{ name: "ادارة الحسابات الخاصة", link: "/finance/accounting/36", permission: "15634"},
							{ name: "مقبوضات الحسابات الخاصة", link: "/finance/accounting/37", permission: "15635"},
							{ name: "مصروفات الحسابات الخاصة", link: "/finance/accounting/38", permission: "15636"},
							{ name: "الإستعلام الحسابات الخاصة", link: "/finance/accounting/39", permission: "15637"},
							{ name: "تحصيلات فواتير المبادرة", link: "/finance/accounting/42", permission: "15880"},
							{ name: "الإستعلام عن فواتير المبادرة", link: "/finance/accounting/43", permission: "15880"},
						]
					},
					{
						name: "الإعدادات ومراكز التكلفة",
						isGroup: true,
						pages: [
							{ name: "الضرائب", link: "/setting/accounting/6", permission: "15624"},
							{ name: "مجموعات الضرائب", link: "/setting/accounting/7", permission: "15628"},
							{ name: "أنواع ضرائب البنود المخزنية", link: "/setting/accounting/8", permission: "15617"},
							{ name: "اعدادات الأستاذ العام", link: "/setting/accounting/9", permission: "15626"},
							{ name: "السنوات المالية", link: "/setting/accounting/10", permission: "15625"},
							{ name: "ترحيل الأرباح السنوية", link: "/setting/accounting/32", permission: "15625"},
							{ name: "إغلاق الفترات للفرع", link: "/setting/accounting/29", permission: "528"},
							{ name: "البحث في مراكز التكلفة", link: "/finance/accounting/30", permission: "13313"},
							{ name: "الكلمات الدلالية", link: "/finance/accounting/27", permission: "13057"},
							{ name: "إضافة مركز تكلفة", link: "/finance/accounting/28", permission: "13314"},
						]
					},
					{
						name: "العملات",
						isGroup: true,
						pages: [
							{ name: "العملات", link: "/finance/accounting/20", permission: "15622"},
							{ name: "معدل تبادل العملات", link: "/finance/accounting/21", permission: "15887"},
							{ name: "تحديث قيمة العملة", link: "/finance/accounting/26", permission: "15875"},
						]
					},
					{
						name: "التقارير والإستعلامات",
						isGroup: true,
						pages: [
							{ name: "تحصيلات الفروع", link: "/finance/accounting/32", permission: "15885"},
							{ name: "الاستعلام عن دفتر اليومية", link: "/finance/accounting/08", permission: "16129"},
							{ name: "الاستعلام عن الأستاذ العام", link: "/finance/accounting/09", permission: "15874"},
							{ name: "إستعلام الحسابات المالية", link: "/finance/accounting/10", permission: "15888"},
							{ name: "الأستعلام عن الضريبة", link: "/finance/accounting/11", permission: "16130"},
							{ name: "التقارير البنكية", link: "/finance/accounting/15", permission: "16131"},
							{ name: "تقارير الأستاذ العام", link: "/finance/accounting/16", permission: "16132"},
							{ name: " تقارير مراكز التكلفة", link: "/finance/accounting/31", permission: "13315"},
						]
					},
				]
			},
			{
				id: "sales",
				appID: "accounting",
				order: 5,
				name: "Sales Administration",
				icon: "icon-menu-06",
				link: "/sales/reports/overview",
				pages: [
					{ name: "نظرة عامة على المبيعات", link: "/sales/reports/overview", className: "overview", permission: "3102"},
					{
						name: "عمليات البيع والفواتير",
						isGroup: true,
						pages: [
							{ name: "فاتورة مبيعات مباشرة",  link: "/sales/accounting/04", permission: "3077"},
							{ name: "إستعلام عن فواتير المبيعات",  link: "/sales/accounting/32", permission: "3084"},
							{ name: "إدخال عرض سعر",  link: "/sales/accounting/01", permission: "3107"},
							{ name: "فاتورة المبادرة",  link: "/sales/accounting/37", permission: "3094"},
							{ name: "إدخال طلبات البيع",  link: "/sales/accounting/02", permission: "3075"},
							{ name: "عمليات التسليم المباشرة",  link: "/sales/accounting/03", permission: "3076"},
							{ name: "الفواتير الدورية",  link: "/sales/accounting/22", permission: "2823"},
							{ name: "نماذج الفواتير الجاهزة",  link: "/sales/accounting/09", permission: "3086"},
							{ name: "فواتير الدفع المسبق",  link: "/sales/accounting/07", permission: "3088"},
							{ name: "سندات التسليم الجاهزة",  link: "/sales/accounting/08", permission: "3087"},
							{ name: "إنشاء الفواتير المتكررة",  link: "/sales/accounting/10", permission: "3089"},
							// { name: "فاتورة مبيعات (تجريبية)",  link: "/sales/invoice", permission: "3077"},
						]
					},
					{
						name: "المندوبين والإعدادات",
						isGroup: true,
						pages: [
							{ name: "مردودات المبيعات",  link: "/sales/accounting/12", permission: "3092"},
							{ name: "إستعلام عن مردودات المبيعات",  link: "/sales/accounting/31", permission: "3090"},
							{ name: "مردودات فاتورة المبادرة",  link: "/sales/accounting/39", permission: "3109"},
							{ name: "مردودات المبادرة السابقة",  link: "/sales/accounting/47", permission: "3109"},
							{ name: " اشعار دائن ضريبي",  link: "/sales/accounting/28", permission: "3092"},
							{ name: "اشعار مدين ضريبي",  link: "/sales/accounting/29", permission: "3091"},
							{ name: "أنواع المبيعات",  link: "/sales/accounting/23", permission: "2817"},
							{ name: "مناطق البيع",  link: "/sales/accounting/25", permission: "2820"},
							{ name: "إعدادات حالة الآئتمان",  link: "/sales/accounting/26", permission: "521"},
							{ name: "إدارة البائعين",  link: "/sales/accounting/49", permission: "2819"},
							{ name: "مبيعات ونسبة المندوبين",  link: "/sales/accounting/30", permission: "3093"},
							{ name: "تحصيلات المندوبين",  link: "/sales/accounting/34", permission: "3094"},
							{ name: "مرودات مبيعات سابقة",  link: "/sales/accounting/45", permission: "3112"},
						]
					},
					{
						name: "إدارة العملاء",
						isGroup: true,
						pages: [
							{ name: "التحكم العملاء",  link: "/sales/accounting/18", permission: "3074"},
							{ name: "تخصيصات العميل",  link: "/sales/accounting/13", permission: "3081"},
							{ name: "الحركات الخاصة بالعميل",  link: "/sales/accounting/16", permission: "3073"},
							{ name: "مجموعات المبيعات",  link: "/sales/accounting/21", permission: "2821"},
							{ name: "مقبوضات العملاء",  link: "/sales/accounting/11", permission: "3080"},
							{ name: "مدفوعات العملاء",  link: "/sales/accounting/48", permission: "3122"},
							{ name: "فروع العملاء",  link: "/sales/accounting/20", permission: "3095"},
							{ name: "فواتير المبيعات المتأخرة",  link: "/sales/accounting/27", permission: "3096"},
							{ name: "شروط الدفع", link: "/setting/accounting/12", permission: "519"},
							{ name: "شركة الشحن", link: "/setting/accounting/13", permission: "520"},
							{ name: "نقاط البيع", link: "/setting/accounting/14", permission: "516"},
						]
					},
					{
						name: "الإستعلامات والتقارير",
						isGroup: true,
						pages: [
							{ name: "الإستعلام عن التخصيصات",  link: "/sales/accounting/17", permission: "3097"},
							{ name: "إستعلام طلبات المبيعات",  link: "/sales/accounting/05", permission: "3085"},
							{ name: "إستعلام عن عمليات التسليم",  link: "/sales/accounting/06", permission: "3082"},
							{ name: "الأستعلام عن خطاب تسعير",  link: "/sales/accounting/14", permission: "3100"},
							{ name: "الإستعلام عن طلبات المبيعات",  link: "/sales/accounting/15", permission: "3100"},
							{ name: "تقارير المبيعات و العملاء",  link: "/sales/accounting/19", permission: "3329"},
							{ name: "بيانات العملاء",  link: "/sales/accounting/33", permission: "3108"},
							{ name: "خصومات المشغلين اليومية",  link: "/sales/accounting/35", permission: "3098"},
							{ name: "تقرير يومية المبيعات",  link: "/sales/accounting/36", permission: "3099"},
							{ name: "تقرير المردودات",  link: "/sales/accounting/38", permission: "3090"},
							{ name: "فواتير المبيعات المتأخرة", link: "/sales/accounting/46", permission: "3116"},
						]
					},
					{
						name: "المراجعة",
						isGroup: true,
						pages: [
							{ name: "مراجعة فواتير المبيعات", link: "/sales/accounting/40", permission: "3084"},
							{ name: "مراجعة مردود المبيعات", link: "/sales/accounting/41", permission: "3090"},
							{ name: "مراجعة عمليات التسليم", link: "/sales/accounting/42", permission: "3082"},
							{ name: "مراجعة اوامر البيع", link: "/sales/accounting/43", permission: "3073"},
							{ name: "مراجعة عروض التسعير", link: "/sales/accounting/44", permission: "3100"},
						]
					},
				]
			},
			{
				id: "fixed_assets",
				appID: "accounting",
				order: 7,
				name: "Fixed Assets Administration",
				icon: "icon-menu-09",
				link: "/fixed_assets/reports/overview",
				pages: [
					{ name: "نظرة عامة على الأصول", link: "/fixed_assets/reports/overview", className: "overview", permission: "9481"},
					{
						name: "الأصول والتصنيفات",
						isGroup: true,
						pages: [
							{name: "الأصول الثابتة", link: "/fixed_assets/accounting/09", permission: "9217"},
							{name: "تصنيفات الأصول ", link: "/fixed_assets/accounting/11", permission: "9218"},
							{name: "فئات الأصول", link: "/fixed_assets/accounting/12", permission: "9220"},
							{name: "مصروفات الأصول", link: "/fixed_assets/accounting/13", permission: "9477"},
							{name: "الإستعلام عن المصروفات", link: "/fixed_assets/accounting/14", permission: "9478"},
						]
					},
					{
						name: "عمليات الأصول",
						isGroup: true,
						pages: [
							{name: "شراء الأصول ", link: "/fixed_assets/accounting/01", permission: "9479"},
							{name: "نقل الأصول ", link: "/fixed_assets/accounting/02", permission: "9474"},
							{ name: "التصرف في الأصول ", link: "/fixed_assets/accounting/03", permission: "9475"},
							{ name: "بيع الأصول ", link: "/fixed_assets/accounting/04", permission: "9480"},
							{ name: "عملية الإهلاك", link: "/fixed_assets/accounting/05", permission: "9476"},
						]
					},
					{
						name: "الإستعلامات والتقارير",
						isGroup: true,
						pages: [
							{ name: "حركات الأصول", link: "/fixed_assets/accounting/06", permission: "9473"},
							{ name: "إستعلامات عن الأصول", link: "/fixed_assets/accounting/07", permission: "9482"},
							{ name: "تقارير الأصول الثابتة", link: "/fixed_assets/accounting/08", permission: "9729"},
						]
					},
				]
			},
			// {
			// 	id: "crm",
			// 	appID: "accounting",
			// 	order: 7,
			// 	name: "CRM Administration",
			// 	icon: "icon-menu-07",
			// 	link: "/crm/reports/overview",
			// 	pages: [
			// 		{ name: "نظرة عامة على علاقات العملاء", link: "/crm/reports/overview", className: "overview", permission: "615530"},
			// 		{
			// 			name: "الفرص والمشاريع",
			// 			isGroup: true,
			// 			pages: [
			// 				{ name: "التقديرات", link: "/crm/accounting/01", permission: "15533"},
			// 				{ name: "المهام", link: "/crm/accounting/04", permission: "15528"},
			// 				{ name: "الإقتراحات", link: "/crm/accounting/05", permission: "15534"},
			// 				{ name: "فواتير المشاريع", link: "/crm/accounting/06", permission: "615530"},
			// 				{ name: "فرص التعاقدات", link: "/crm/accounting/07", permission: "15525"},
			// 				{ name: "المشاريع", link: "/crm/accounting/08", permission: "15527"},
			// 			]
			// 		},
			// 		{
			// 			name: "العملاء والتواصل",
			// 			isGroup: true,
			// 			pages: [
			// 				{ name: "التذكيرات", link: "/crm/accounting/09", permission: "15530"},
			// 				{ name: "المكالمات", link: "/crm/accounting/10", permission: "615541"},
			// 				{ name: "الإحتماعات", link: "/crm/accounting/15", permission: "615542"},
			// 				{ name: "العملاء المتوقعين", link: "/crm/accounting/02", permission: "615524"},
			// 				{ name: "وقائع العملاء الحاليين", link: "/crm/accounting/32", permission: "5661"},
			// 				{ name: "الإستعلام عن وقائع العملاء", link: "/crm/accounting/31", permission: "5662"},
			// 			]
			// 		},
			// 		{
			// 			name: "التحكم والإدارة",
			// 			isGroup: true,
			// 			pages: [
			// 				{ name: "أنواع القوائم", link: "/crm/accounting/29", permission: "615546"},
			// 				{ name: "قوائم الإختيار", link: "/crm/accounting/30", permission: "615547"},
			// 				{ name: "المرفقات", link: "/crm/accounting/03", permission: "615548"},
			// 			]
			// 		},
			// 		{
			// 			name: "الإستعلامات والتقارير",
			// 			isGroup: true,
			// 			pages: [
			// 				{ name: "إستعلامات المشاريع", link: "/crm/accounting/18", permission: "615531"},
			// 				{ name: "إستعلامات العملاء المتوقعين", link: "/crm/accounting/17", permission: "615538"},
			// 				{ name: "إستعلامات الفرص", link: "/crm/accounting/16", permission: "615540"},
			// 				{ name: "إستعلامات المهام", link: "/crm/accounting/19", permission: "615539"},
			// 				{ name: "الإستعلام عن الموردين", link: "/crm/accounting/12", permission: "615536"},
			// 				{ name: "إستعلامات التقديرات", link: "/crm/accounting/13", permission: "615544"},
			// 				{ name: "إستعلامات الإقتراحات", link: "/crm/accounting/14", permission: "615543"},
			// 				{ name: "تقارير إدارة العملاء", link: "/crm/accounting/20", permission: "615539"},
			// 			]
			// 		},
			// 	]
			// },
			{
				id: "maintenance",
				appID: "accounting",
				order: 8,
				name: "Maintenance Administration",
				icon: "icon-menu-08",
				link: "/maintenance/reports/overview",
				pages: [
					{ name: "نظرة عامة على الصيانة", link: "/maintenance/reports/overview", className: "overview", permission: "56291"},
					{ name: "طلبات الصيانة", link: "/maintenance/accounting/02", permission: "65283"},
					{ name: "فاتورة صيانة", link: "/maintenance/accounting/06", permission: "65284"},
					{ name: "إدارة الورش", link: "/maintenance/accounting/07", permission: "65285"},
					{ name: "إدارة الفنين", link: "/maintenance/accounting/01", permission: "65286"},
					{ name: "فاتورة مشتريات قطع غيار", link: "/maintenance/accounting/05", permission: "65287"},
					{ name: "الإستعلام عن طلبات الصيانة", link: "/maintenance/accounting/03", permission: "65288"},
					{ name: "تحويل بضاعه للفروع", link: "/maintenance/accounting/04", permission: "65289"},
					{ name: "الإستعلام عن حركات الصيانة", link: "/maintenance/accounting/08", permission: "65290"},
					{ name: "إضافة بضاعة الأعطال", link: "/maintenance/accounting/09", permission: "8461"},
					{ name: "الإستعلام عن بضاعة الأعطال", link: "/maintenance/accounting/10", permission: "8461"},
				]
			}
		]
	}
}

