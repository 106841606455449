import Layout from "./index";

export {Layout}

export default {
	routes: [],
	reducers: {},
	i18n: {
		en: {
			dir: "ltr",
			hr: "Human Resources",
			global_search_placeholder: "Search in people, tasks, documents ... and more"
		},
		ar: {
			dir: "rtl",
			right: "left",
			left: "right",
			"Dark Mode": "الوضع الغامق",
			"Full Screen": "تكبير الشاشة",
			"Overview": "نظرة عامة",
			hr: "الموارد البشرية",
			"Finance Administration": "إدارة المالية",
			"Inventory Administration": "إدارة المخازن",
			"Purchases Administration": "إدارة المشتريات",
			"Sales Administration": "إدارة المبيعات",
			"CRM Administration": "إدارة علاقات العملاء",
			"Maintenance Administration": "إدارة الصيانة",
			"Fixed Assets Administration": "إدارة الأصول الثابتة",
			"Tasks Administration": "إدارة المهام",
			"Intercom Administration": "إدارة التواصل",
			"Drive Administration": "إدارة الملفات",
			"Business Intelligence": "تقارير الإدارة",
			"General Setting": "الإعدادات العامة",
			"Reports": "التقارير والإستعلامات",
			global_search_placeholder: "البحث عن الأشخاص، المهام، المستندات ... والمزيد",
			"My Account": "حسابي",
			"Logout": "تسجيل خروج",
			"System Overview": "رئيسية النظام",
			Unauthorized: "غير مصرح لك بالوصول",
			"Unable to connect to the server, Check your internet connection": "تعذر الإتصال بالإنترنت، برجاء التاكد من الإتصال.",
			"Loading": "جاري التحميل",
			Accounting: "المالية",
			System: "النظام",
			core: "النظام",
			users: "العضويات",
			"Enter email and password?": "البريد الإلكتروني وكلمة المرور مطلوبين",
			"Login failed, invalid email or password": "بيانات تسجيل الدخول غير صحيحة"
		}
	},
	navigation: {
		menu: [
			{
				id: "system",
				order: 0,
				name: "System Overview",
				icon: "icon-menu-01",
				link: "/",
				pages: []
			},
			{
				id: "reports",
				permission: "monitor",
				order: 24.1,
				name: "Reports",
				icon: "icon-menu-14",
				link: "/setting/accounting/31",
				pages: []
			},
			{
				id: "setting",
				order: 24.2,
				name: "General Setting",
				icon: "icon-menu-13",
				pages: [
					{
						name: "System",
						isGroup: true,
						pages: [
							{ name: "العضويات والحسابات", link: "/core/crud/users" },
							{ name: "إرسال رسالة جماعية", link: "/notifications/bulk" },
						]
					}
				]
			},
		]
	}
}

