import React, {lazy} from "react";
import { Route } from "react-router-dom";
import Reducer from "./reducer";

const Report  = lazy(() => import("./index"));
const CustomReport  = lazy(() => import("./CustomReport"));

export default {
	reducers: { reports: Reducer },
	routes: [
		<Route key="custom_report" exact path="/home" component={CustomReport}/>,
		<Route
			key="report"
			exact
			path="/:app/reports/:type"
			component={props => <Report key={props.location.pathname} {...props} />}
		/>
	],
	i18n: {
		en: {},
		ar: {
			"Event Planning Report": "تقرير تخطيط الفعاليات",
			"Number of Customers": "عدد العملاء",
			"Number of Branches": "عدد الفروع",
			"Number of Salesmen": "عدد مندوبي المبيعات",
			"Recurred Invoices": "الفواتير المتكررة",
			"Customers Allocations per Date": "مخصصات العملاء بالتاريخ",
			"Customers Transactions per Date": "مشتريات العملاء بالتاريخ",
			"Overview Report": "نظرة عامة",
			"Total Number of Records": "اجمالي القيود",
			"Journal Ledger": "اجمالي مجموعات حساب الأستاذ",
			"Journal Ledger Classes": "اجمالي التصنيفات لحساب الأستاذ",
			"Overdue Sales Invoices": "الفواتير المتأخرة",
			"Profit and Loss": "أرباح وخسائر",
			"Top Clients": "العملاء المميزين",
			"Delivered Transactions": "اجمالي المشتريات",
			"Bank Balance": "الحساب البنكي",
			sales: "إدارة المبيعات",
			crm: "إدارة علاقات العملاء",
			maintenance: "إدارة الصيانة",
			drive: "إدارة الملفات",
			finance: "إدارة المالية",
			purchases: "إدارة المشتريات",
			inventory: "إدارة المخازن",
			fixed_assets: "إدارة الأصول الثابتة",
			"Select a Dashboard": "إختيار التقرير",
			"New Dashboard": "إنشاء تقرير جديد",
			"Add Block": "إضافة رسم بياني",
			"Customize Report": "تخصيص التقرير",
			"Vacations Total": "مجموع الإجازات",
			"Vacations per Status": "الإجازات لكل حالة",
			"Total vacations per Employee": "مجموع الإجازات لكل موظف",
			"Payables Total": "مجموع الذمم الدائنة",
			"Payables per Employee": "حسابات الدفع لكل موظف",
			"Payables per Status": "حسابات الدفع لكل حالة",
			"Payables per due date": "حسابات الدفع لكل تاريخ استحقاق",
			"Tickets per Employee": "تذاكر لكل موظف",
			"Tickets per Status": "تذاكر لكل حالة",
			"Total Attendances": "إجمالي الحضور",
			"Attendances per Employee": "الحضور لكل موظف",
			"Attendances per Type": "الحضور لكل نوع",
			"Total Facts": "إجمالي الحقائق",
			"Fact per Status": "حقيقة لكل حالة",
			"Fact Per Action": "حقيقة لكل عمل",
			"Fact per Employee": "حقيقة لكل موظف",
			"Fact per Month": "حقيقة في الشهر",
			"Evaluations per Status": "التقييمات لكل حالة",
			"Evaluations per Employees": "تقييمات لكل موظف",
			"Evaluations per Month": "تقييمات شهرية",
			"Hiring per Status": "التوظيف لكل حالة",
			"Hiring per Expect Salary": "التوظيف لكل الراتب المتوقع",
			"Hiring per Age": "التوظيف لكل سن",
			"Hiring per Job Title": "التوظيف لكل مسمى وظيفي",
			"Hiring per Gender": "التوظيف لكل جنس",
			"Total Training": "إجمالي التدريب",
			"Training Per Name": "التدريب لكل اسم",
			"Training Per Trainer Name": "اسم التدريب لكل مدرب",
			"Training Per Status": "التدريب لكل حالة",
			"Training Per Start Date": "التدريب حسب تاريخ البدء",
			"Training Per End Date": "التدريب لكل تاريخ انتهاء",
			"Total loans": "إجمالي القروض",
			"Total amount": "المبلغ الإجمالي",
			"Loans per Employee": "قروض لكل موظف",
			"Loans per Received At": "القروض لكل مستلم",
			"Total Installments": "إجمالي الأقساط",
			"Installments per Employee": "الأقساط لكل موظف",
			"Installments per Amount Employee": "أقساط لكل موظف",
			"Installments per Status": "الأقساط حسب الحالة",
			"Installments per Payment Date": "الأقساط في تاريخ الدفع",
			"Total Amount": "المبلغ الإجمالي",
			"Cancel Customization": "إلغاء التخصيصات",
			Number: "رقمي",
			Pie: "دائري",
			Doughnut: "دائري مفرغ",
			Bar: "أعمدة",
			Line: "خطي",
			Polar: "رادار",
			Blank: "فارغ",
			Basic: "عام"
		}
	},
	navigation: {
		menu: []
	}
};
