import React, {useState, useEffect, useRef} from 'react';
import { Stage, Layer } from 'react-konva';
import {NumericInput, Icon} from "@blueprintjs/core";
import api from "../../../utilities/API";
import Shape from "./Shape";
import Panels from "./Panels";
import "../style.scss";

export default function Seating()
{
    const [selected, setSelected] = useState(null);
    const [map, setMap] = useState(null);
    const stageRef = useRef(null);

    //
    useEffect(() => {
        api("/commerce/seats/1", { // fixme: get from url
            result: ({data}) => {
                data.data = JSON.parse(data.data);
                setMap(data);
            }
        });
    }, []);

    //
    const onSave = () =>
    {
        let newMap = {...map};
        newMap.data = stageRef.current.toJSON();

        api("/commerce/seats/1", { // fixme: get from url
            method: "PUT",
            data: newMap,
            result: ({data}) => {
                // todo: finish loading
            }
        });
    }

    // zoom on edit seats
    useEffect(() => {
        const stage = stageRef.current;
        if(!stage || !selected || !selected.seats || selected.row) {
            return
        }

        // get the selected dimensions
        let box = stage.find("#"+selected.id);
        if(!box.length) {
            return;
        }

        box = box[0].getClientRect();
        const scale = Math.max(stage.width() / box.width, stage.height() / box.height);

        // set the zoom
        stage.to({
            x: -box.x * scale,
            y: -box.y * scale,
            scaleX: scale,
            scaleY: scale,
        })
    }, [selected]);

    //
    const checkDeselect = (e) => {
        if(selected && selected.seats) {
            return;
        }

        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelected(null);
        }
    };

    if(!map) {
        return <div>Loading..</div>;
    }

    const shapes = map.data.children[0].children;

    //
    const addSection = (props) =>
    {
        shapes.push({
            className: "Group",
            attrs: { type: props.type, x: 100, y: 100, rotation: 0, id: "s"+(new Date).getTime(), text: "Section" },
            children: [
                {
                    className: props.type, attrs: {
                        fill: "#2EE064", className: props.type, strokeWidth: 0, stroke: "black", shadowBlur: 0, cornerRadius: 0, ...props
                    }
                }
            ]
        });
        setMap({...map});
    }

    return (
        <div id="seating-editor" dir="ltr">
            <Panels {...{map, setMap, shapes, selected}} />
            <div id="board">
                <div id="toolbar">
                    {(!selected || !selected.seats) &&
                    <>
                        <button onClick={() => addSection({type: "Rect", width: 100, height: 100})}><Icon iconSize="20" color="#555" icon="square" /></button>
                        <button onClick={() => addSection({type: "Circle", radius: 70})}><Icon iconSize="20" color="#555" icon="circle" /></button>
                        <button onClick={() => addSection({type: "Wedge", radius: 70, angle: 60})}><Icon iconSize="20" color="#555" icon="symbol-triangle-down" /></button>
                        <button onClick={() => addSection({type: "Arc", innerRadius: 40, outerRadius: 70, angle: 60})} ><Icon iconSize="20" color="#555" icon="doughnut-chart" /></button>
                        <button onClick={() => addSection({type: "RegularPolygon", radius: 70, sides: 6})} ><Icon iconSize="20" color="#555" icon="polygon-filter" /></button>
                        <button onClick={() => addSection({type: "Text", text: 'Simple Text', fontSize: 30, fontFamily: 'Calibri'})}><Icon iconSize="20" color="#555" icon="new-text-box" /></button>
                    </>}

                    <div className="row-seting">
                        {selected && selected.seats &&
                        <>
                            <button onClick={() => {
                                setSelected(null);
                                stageRef.current.to({ x: 0, y: 0, scaleX: 1, scaleY: 1 });
                            }}><Icon iconSize="20" color="#555" icon="drawer-right-filled" /></button>

                            <button onClick={() => {
                                if(!shapes[selected.index].children) {
                                    shapes[selected.index].children = [];
                                }

                                shapes[selected.index].children.push({
                                    className: "Group",
                                    attrs: {type: "row", x: 20, y: 20, rowRotation: 0, seatsCount: 10, seatSize: 2, space: 7, curve: 0, seatsColor: "red", id: "s"+(new Date).getTime() }
                                });
                                setMap({...map});
                            }}><Icon iconSize="20" color="#555" icon="more" /></button>
                        </>}
                    </div>

                    {selected && !selected.seats &&
                    <button onClick={() => { shapes.splice(selected.index, 1); setMap({...map}); setSelected(null); } }><Icon iconSize="20" color="#555" icon="trash" /></button>}

                    <button onClick={() => stageRef.current.to({scaleX: 1, scaleY: 1})}><Icon iconSize="20" color="#555" icon="zoom-to-fit" /></button>
                    <button onClick={() => stageRef.current.to({scaleX: stageRef.current.scaleX() + 0.2, scaleY: stageRef.current.scaleY() + 0.2})}><Icon iconSize="20" color="#555" icon="zoom-in" /></button>
                    <button onClick={() => stageRef.current.to({scaleX: stageRef.current.scaleX() - 0.2, scaleY: stageRef.current.scaleY() - 0.2})}><Icon iconSize="20" color="#555" icon="zoom-out" /></button>

                    <button onClick={onSave}><Icon iconSize="20" color="#555" icon="floppy-disk" /></button>
                </div>

                <Stage ref={stageRef} draggable width="1000" height="700" onMouseDown={checkDeselect} onTouchStart={checkDeselect} className="stage">
                    <Layer>
                        {shapes.map((rect, i) =>
                            <Shape
                                key={i}
                                group={rect}
                                selected={selected}
                                onSelect={(e, seats, row) => setSelected({id: rect.attrs.id+"", index: i, seats, row, attrs: rect.attrs, children: rect.children})}
                                onChange={(newAttrs, child, childAttrs) => {
                                    shapes[i].attrs = newAttrs;
                                    setMap({...map});
                                }}
                            />
                        )}
                    </Layer>
                </Stage>
            </div>
        </div>
    );
};
