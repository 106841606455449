import React from "react";
import {NumericInput} from "@blueprintjs/core";

export default function Panels({map, setMap, shapes, selected})
{
    const onAttrChange = (attr, value) =>
    {
        let shape = shapes[selected.index];

        // graphics attrs
        const graphicsAttrs = ["fill", "width", "height", "strokeWidth", "stroke", "shadowBlur", "cornerRadius", "radius", "sides", "innerRadius", "outerRadius", "angle", "text", "fontSize", "fontFamily"];
        if(graphicsAttrs.includes(attr))
            shape = shape.children[0];

        // row attrs
        const rowAttrs = ["rowID", "seatsCount", "seatsColor", "seatSize", "space", "curve", "rowRotation"];
        if(selected.row && rowAttrs.includes(attr))
            shape = shape.children[selected.row];

        if(attr === "rowRotation") {
            attr = "rotation";
        }
        shape.attrs[attr] = value;
        setMap({...map});
    }

    const fonts = ['Arial Black', 'Arial', 'Comic Sans MS', 'Courier New', 'Georgia', 'Impact', 'Microsoft Sans Serif', 'Tahoma', 'Times New Roman', 'Trebuchet MS', 'Verdana'];

    return (
        <div id="panels">
            {selected && <h2>{selected.attrs.type}</h2>}
            {selected &&
            <>
                <div className="group">
                    <h3>Section</h3>
                    <div className="attr">
                        <label>Section ID</label>
                        <input value={selected.attrs.section_id} onChange={e => onAttrChange("section_id", e.target.value)} />
                    </div>
                </div>

                {selected.row &&
                <div className="group">
                    <h3>Row Setting</h3>
                    <div className="attr">
                        <label>Row ID</label>
                        <input value={selected.children[selected.row].attrs.rowID} onChange={e => onAttrChange("rowID", e.target.value)} />
                    </div>
                    <div className="attr">
                        <label>Number of Seats</label>
                        <NumericInput value={selected.children[selected.row].attrs.seatsCount} onValueChange={v => onAttrChange("seatsCount", v)} />
                    </div>
                    <div className="attr">
                        <label>Color</label>
                        <input type="color" value={selected.children[selected.row].attrs.seatsColor} onChange={e => onAttrChange("seatsColor", e.target.value)} />
                    </div>
                    <div className="attr">
                        <label>Seat Size</label>
                        <NumericInput value={selected.children[selected.row].attrs.seatSize} onValueChange={v => onAttrChange("seatSize", v)} />
                    </div>
                    <div className="attr">
                        <label>Space</label>
                        <NumericInput value={selected.children[selected.row].attrs.space} onValueChange={v => onAttrChange("space", v)} />
                    </div>
                    <div className="attr">
                        <label>Curve</label>
                        <NumericInput value={selected.children[selected.row].attrs.curve} onValueChange={v => onAttrChange("curve", v)} />
                    </div>
                    <div className="attr">
                        <label>rotation</label>
                        <NumericInput value={selected.children[selected.row].attrs.rotation} onValueChange={v => onAttrChange("rowRotation", v)} />
                    </div>
                </div>}

                <div className="group">
                    <h3>Dimensions</h3>
                    <div className="attr">
                        <label>X</label>
                        <NumericInput value={selected.attrs.x} onValueChange={v => onAttrChange("x", v)}  />
                    </div>
                    <div className="attr">
                        <label>Y</label>
                        <NumericInput value={selected.attrs.y} onValueChange={v => onAttrChange("y", v)} />
                    </div>
                    <div className="attr">
                        <label>Rotation</label>
                        <NumericInput value={selected.attrs.rotation} onValueChange={v => onAttrChange("rotation", v)} />
                    </div>
                    {selected.attrs.type === "Rect" &&
                    <>
                        <div className="attr">
                            <label>Width</label>
                            <NumericInput value={selected.children[0].attrs.width} onValueChange={v => onAttrChange("width", v)}  />
                        </div>
                        <div className="attr">
                            <label>Height</label>
                            <NumericInput value={selected.children[0].attrs.height} onValueChange={v => onAttrChange("height", v)} />
                        </div>
                    </>}

                    {["Circle", "Wedge", "RegularPolygon"].includes(selected.attrs.type) &&
                    <div className="attr">
                        <label>Radius</label>
                        <NumericInput value={selected.children[0].attrs.radius} onValueChange={v => onAttrChange("radius", v)}  />
                    </div>}

                    {["Wedge", "Arc"].includes(selected.attrs.type) &&
                    <div className="attr">
                        <label>Angle</label>
                        <NumericInput value={selected.children[0].attrs.angle} onValueChange={v => onAttrChange("angle", v)}  />
                    </div>}

                    {selected.attrs.type === "RegularPolygon" &&
                    <div className="attr">
                        <label>Sides</label>
                        <NumericInput value={selected.children[0].attrs.sides} onValueChange={v => onAttrChange("sides", v)} />
                    </div>}

                    {selected.attrs.type === "Arc" &&
                    <>
                        <div className="attr">
                            <label>Inner Radius</label>
                            <NumericInput value={selected.children[0].attrs.innerRadius} onValueChange={v => onAttrChange("innerRadius", v)} />
                        </div>
                        <div className="attr">
                            <label>Outer Radius</label>
                            <NumericInput value={selected.children[0].attrs.outerRadius} onValueChange={v => onAttrChange("outerRadius", v)} />
                        </div>
                    </>}
                </div>

                <div className="group">
                    <h3>Style</h3>
                    <div className="attr">
                        <label>Color</label>
                        <input type="color" value={selected.children[0].attrs.fill} onChange={e => onAttrChange("fill", e.target.value)} />
                    </div>
                    <div className="attr">
                        <label>Border Size</label>
                        <NumericInput value={selected.children[0].attrs.strokeWidth} onValueChange={v => onAttrChange("strokeWidth", v)} />
                    </div>
                    <div className="attr">
                        <label>Border Color</label>
                        <input type="color" value={selected.children[0].attrs.stroke} onChange={e => onAttrChange("stroke", e.target.value)} />
                    </div>
                    {selected.attrs.type !== "Text" &&
                    <div className="attr">
                        <label>Radius</label>
                        <NumericInput value={selected.children[0].attrs.cornerRadius} onValueChange={v => onAttrChange("cornerRadius", v)} />
                    </div>}
                    <div className="attr">
                        <label>Shadow</label>
                        <NumericInput value={selected.children[0].attrs.shadowBlur} onValueChange={v => onAttrChange("shadowBlur", v)} />
                    </div>
                </div>

                {selected.attrs.type === "Text" &&
                <div className="group">
                    <h3>Text</h3>
                    <div className="attr">
                        <label>Text</label>
                        <input value={selected.children[0].attrs.text} onChange={e => onAttrChange("text", e.target.value)} />
                    </div>
                    <div className="attr">
                        <label>Font Size</label>
                        <NumericInput value={selected.children[0].attrs.fontSize} onValueChange={v => onAttrChange("fontSize", v)} />
                    </div>
                    <div className="attr">
                        <label>Font Family</label>
                        <select value={selected.children[0].attrs.fontFamily} onChange={e => onAttrChange("fontFamily", e.target.value)}>
                            {fonts.map(f => <option value={f}>{f}</option>)}
                        </select>
                    </div>
                </div>}
            </>
            }
        </div>
    );
}