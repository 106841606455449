const initialState = {
	loading: false,
	error: null,
	ready: false,
	user: null,
	notifications: [],
};

export default function reducer(state = initialState, action)
{
	switch (action.type)
	{
		case "PORTAL_SET_USER":
			return {...state, ready: true, user: action.payload};

		case "PORTAL_SET_READY":
			return {...state, ready: action.payload};

		case "PORTAL_SET_NOTIFICATIONS":
			return {...state, notifications: action.payload};

		case "PORTAL_ERROR":
			return {...state, error: action.payload};

		default:
			return state
	}
}