import React from "react";
import {Redirect, Route} from "react-router-dom";
import Reducer from "./reducer";
import i18n from "./i18n";
import Seating from "./Seating";

export default {
	reducers: { exampleApp: Reducer },
	routes: [
		<Route key="commerce" exact path="/commerce" component={() => <Redirect to="/commerce/crud/orders" />} />,
		<Route key="commerce" exact path="/commerce/seating/:id" component={Seating} />,
	],
	i18n,
	navigation: {
		menu: [
			{
				id: "commerce",
				appID: "commerce",
				name: "Commerce Management",
				icon: "icon-menu-03",
				order: 23,
				link: "/commerce/crud/orders",
				pages: [
					{ name: "orders", link: "/commerce/crud/orders", permission: "orders.index"},
					{ name: "products", link: "/commerce/crud/products", permission: "products.index"},
					{ name: "sellers", link: "/commerce/crud/sellers", permission: "sellers.index"},
					{ name: "Sellers locations", link: "/commerce/crud/locations", permission: "locations.index"},
					{ name: "reviews", link: "/commerce/crud/reviews", permission: "reviews.index"},
					{ name: "productFaqs", link: "/commerce/crud/productFaqs", permission: "product-faqs.index"},
					{ name: "categories", link: "/commerce/crud/categories", permission: "categories.index"},
					{ name: "sets", link: "/commerce/crud/sets", permission: "sets.index"},
					{ name: "attributes", link: "/commerce/crud/attributes", permission: "attributes.index"},
					// { name: "addresses", link: "/commerce/crud/addresses", permission: "addresses.index},
					{ name: "stores", link: "/commerce/crud/stores", permission: "stores.index"},
					{ name: "brands", link: "/commerce/crud/brands", permission: "brands.index"},
					{ name: "shippingMethods", link: "/commerce/crud/shippingMethods", permission: "shipping-methods.index"},
					{ name: "coupons", link: "/commerce/crud/coupons", permission: "coupons.index"},
					{ name: "users", link: "/commerce/crud/users", permission: "users.index"},
					{ name: "roles", link: "/commerce/crud/roles", permission: "roles.index"},
					{ name: "countries", link: "/commerce/crud/countries", permission: "countries.index"},
					{ name: "cities", link: "/commerce/crud/cities", permission: "cities.index"},

					// cms
					// { name: "contents", link: "/cms/crud/contents", permission: "sellers.index"}, // todo: fix permissions
					// { name: "menus", link: "/cms/crud/menus", permission: "menus.index"},
					// { name: "sliders", link: "/cms/crud/sliders", permission: "sliders.index"},
					// { name: "Website Categories", link: "/cms/crud/categories", permission: "categories.index"},
					// { name: "contentTypes", link: "/cms/crud/contentTypes", permission: "content-types.index"},
					// { name: "fields", link: "/cms/crud/fields", permission: "fields.index"},
					// { name: "comments", link: "/cms/crud/comments", permission: "comments.index"},
					// { name: "tags", link: "/cms/crud/tags", permission: "tags.index"},
					// { name: "websites", link: "/cms/crud/websites", permission: "websites.index"},
				]
			}
		]
	}
}

