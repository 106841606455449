import api from "./utilities/API";
import storage from "./utilities/storage";

//
export function login(email, password)
{
	return function(dispatch)
	{
		dispatch({type: "PORTAL_LOADING"});

		api("/auth/login", {
			method: "POST",
			data: {email, password},

			result: ({data}) => {

				storage("access_token", data.token);
				// add it as a cookie for backend applications that may need it
				storage("access_token", data.token, true);

				setUser(dispatch, data.user);
			},
			error: (error) => dispatch({
				type: "PORTAL_ERROR",
				payload: error
			}),
		})
	}
}

//
export function logout()
{
	return function(dispatch)
	{
		storage("access_token", null);
		storage("access_token", null, true);
		dispatch({type: "PORTAL_SET_USER", payload: null});
	}
}

//
export function loadUser()
{
	return function(dispatch)
	{
		if(!storage("access_token")) {
			dispatch({type: "PORTAL_SET_READY", payload: true});
			return
		}

		dispatch({type: "PORTAL_LOADING"});

		api("/auth/user", {
			result: ({data}) => setUser(dispatch, data),

			error: (error, details) => {
				if(details.response && details.response.status === 401) {
					storage("access_token", null);
					dispatch({type: "PORTAL_SET_READY", payload: true});
				} else {
					dispatch({type: "PORTAL_ERROR", payload: error});
				}
			},
		})
	}
}

//
function setUser(dispatch, user)
{
	// set core permissions
	let corePermissions = {};
	(user.permissions || "").split(",").forEach(p => {
		corePermissions[p] = 1;
	});

	user.permissions = {
		core: corePermissions
	};

	user.can = function (app, permission) {
		if(!permission) {
			return true;
		}

		return (user.permissions[app] || {})[permission];
	};

	dispatch({type: "PORTAL_SET_USER", payload: user});

	// todo: load other apps permissions
	["hr", "accounting", "commerce"].map(app => {
		let url = "/"+app+"/profile";
		if(app === "accounting") {
			url = "/accounting/admin/permission_end_point.php";
		}

		api(url, {
			result: ({data}) => {
				user.permissions[app] = data.permissions;
				dispatch({type: "PORTAL_SET_USER", payload: {...user}});
			}
		});
	})
}

//
export function loadNotifications()
{
	return function(dispatch)
	{
		api("/notifications/", {
			result: ({data}) => dispatch({type: "PORTAL_SET_NOTIFICATIONS", payload: data})
		})
	}
}

//
export function setReady(isReady) {
	return function(dispatch) {
		dispatch({type: "PORTAL_SET_READY", payload: isReady});
	}
}