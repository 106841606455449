import React from "react";
import {Route} from "react-router-dom";
import Static from "./index"

export default {
	reducers: {},
	routes: [
		<Route key="crud_list" exact path="/:app/static/:id" component={(props) => <Static key={props.location.pathname} {...props}/>} />,
	],
	navigation: {
		menu: []
	},
	i18n: {
		ar: {},
		en: {},
	}
}

