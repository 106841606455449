import React from "react";
import Reducer from "./reducer";

export default {
	reducers: {cms: Reducer},
	routes: [],
	i18n: {
		en: {
			cms: "Website",
			categories: "Categories",
			comments: "Comments",
			contents: "Contents",
			contentType: "Content Types",
			fields: "Fields",
			menus: "Menus",
			sliders: "Sliders",
			tags: "Tags",
			websites: "Websites",

			title: "Title",
			body: "Body",
			user_id: "User ID",
			website_id: "Website ID",
			comment: "Comment",
			content_id: "Content ID",
			status: "Status",
			slug: "Slug",
			items: "Items",
			Item: "Item",
			with_title: "With Title",
			photo: "Photo",
			domain: "Domain",
			Icon: "Icon",
		},
		ar: {
			cms: "الموقع الإلكتروني",
			categories: "إدارة الفئات",
			comments: "إدارة التعليقات",
			contents: "الصفحات والمحتوي",
			contentTypes: "إدارة أنواع المحتوى",
			fields: "إدارة الحقول",
			menus: "إدارة القوائم",
			sliders: "إدارة الشرائح",
			tags: "إدارة الوسوم",
			websites: "إدارة المواقع",

			title: "العنوان",
			body: "المحتوى",
			user_id: "المستخدم",
			website_id: "رقم الموقع",
			comment: "التعليق",
			content_id: "رقم المحتوى",
			status: "الحالة",
			slug: "معرف الرابط",
			items: "العناصر",
			Item: "العنصر",
			with_title: "إظهار العنوان",
			photo: "صورة",
			domain: "النطاق",
			Icon: "الأيقونة",
			"Page Builder": "تخطيط الصفحة",
			"Website Categories": "تصنيفات الموقع"
		}
	},
	navigation: {
		menu: [
			{
				id: "cms",
				name: "cms",
				icon: "icon-menu-08",
				order: 22,
				pages: [
					{ name: "Overview", link: "/cms/reports/overview" },
					{ name: "contents", link: "/cms/crud/contents" },
					{ name: "categories", link: "/cms/crud/categories" },
					{ name: "contentTypes", link: "/cms/crud/contentTypes" },
					{ name: "fields", link: "/cms/crud/fields" },
					{ name: "menus", link: "/cms/crud/menus" },
					{ name: "sliders", link: "/cms/crud/sliders" },
					{ name: "websites", link: "/cms/crud/websites" },
					{ name: "comments", link: "/cms/crud/comments" },
					{ name: "tags", link: "/cms/crud/tags" },
				]
			}
		]
	}
}

