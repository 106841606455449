import React from "react";
import * as t from "counterpart";
import {Button, Callout, Icon} from "@blueprintjs/core";

import "./style.scss"

class Login extends React.Component
{
	state = {};

	constructor(props)
	{
		super(props);

		this.onSubmit = () => {
			const {email, password} = this.state;
			if(!email || !password) {
				this.setState({error: "Enter email and password?"});
				return;
			}

			props.onSubmit(email, password);
		};
	}

	componentDidUpdate(prevProps) {
		const error = this.props.error;
		if(prevProps.error !== error)
			this.setState({error})
	}

	render()
	{
		const error = this.state.error;

		const onChange = (e) => {
			this.setState({[e.target.name]: e.target.value});
		};

		const onKeyDown = (e) => {
			if(e.key === "Enter")
				this.onSubmit()
		};

		return (
			<div id="login-page" dir="rtl">
				<div id="login-box">
					<img src="/assets/logo.svg" alt=""/>

					<h1>تسجيل دخول مورد</h1>

					{error && <Callout intent="danger">{t(error || " ")}</Callout>}

					<div className="field">
						<Icon icon="user" />
						<input name="email" className="bp3-input bp3-intent-primary bp3-fill" type="text" placeholder="إسم المستخدم" onChange={onChange} onKeyDown={onKeyDown} />
					</div>

					<div className="field">
						<Icon icon="lock" />
						<input name="password" className="bp3-input bp3-intent-primary bp3-fill" type="password" placeholder="كلمة المرور" onChange={onChange} onKeyDown={onKeyDown}  />
					</div>

					<Button text="تسجيل الدخول" intent="primary" onClick={this.onSubmit} />

					<a href="/" style={{visibility: "hidden"}}>نسيت كلمة المرور؟</a>
				</div>
			</div>
		);
	}
}

export default Login;