import React, {Component} from 'react';

class Static extends Component
{
	constructor(props) {
		super(props);
		this.state = {img: props.location.search.replace("?i=", "")}
	}

	render()
	{
		return <div>
			<img src={"https://portal-admin-demo.uplift.plus/"+this.state.img} alt="" style={{transform: "scale(1.035)", width: "100%"}}/>
		</div>;
	}
}

export default Static;