import React, {lazy} from "react";
import {Redirect, Route} from "react-router-dom";


const Structure  = lazy(() => import("./components/Structure"));
const EndOfService  = lazy(() => import("./components/EndOfService"));
const Setting  = lazy(() => import("./components/Setting"));
const PrepareSalaries  = lazy(() => import("./components/PrepareSalaries"));

export default {
	reducers: {},
	routes: [
		<Route key="hr" exact path="/hr" component={() => <Redirect to="/hr/reports/overview" />} />,
		<Route key="hr" exact path="/hr/structure" component={Structure} />,
		<Route key="hr" exact path="/hr/end_of_service" component={EndOfService} />,
		<Route key="hr" exact path="/hr/salaries/prepare" component={PrepareSalaries} />,
		<Route key="hr" exact path="/hr/setting" component={Setting} />,
	],
	i18n: {
		en: {
			employees: "Employees",
			countries: "Countries",
			cities: "Cities",
			employee_id: "Employee",
			type: "Type",
			date: "Date",
			first_name: "First Name",
			middle_name: "Middle Name",
			last_name: "Last Name",
			first_name_ar: "First Name (AR)",
			middle_name_ar: "Middle Name (AR)",
			last_name_ar: "Last Name (AR)",
			attendances: "Attendance",
			departments: "Departments",
			evaluations: "Evaluations",
			facts: "Facts",
			loans: "Loans",
			installments: "Loans Installments",
			payables: "Salaries & Payments",
			tickets: "Tickets",
			vacations: "Vacations",
			training: "Training",
			hiring: "Hiring",
			setting: "Setting",
			titles: "Job Titles",
			job_title: "Job Title",
			residence_city_id: "Residence City",
			title_id: "Job Title",
			leave_of_absences: "Leave of Absence",
			department_id: "Department",
			gender: "Gender",
			birthday: "Birthday",
			salary: "Salary",
			phone: "Phone",
			mobile: "Mobile",
			transportation_allowance: "Transportation Allowance",
			housing_allowance: "Housing Allowance",
			other_allowance: "Other Allowance",
			salary_notes: "Salary Notes",
			emergency_contact_name: "Emergency Contact Name",
			emergency_contact_number: "Emergency Contact Number",
			leave_date: "Leaving Work Date",
			manager_id: "Direct Manager",
			birth_city_id: "Birth City",
			residence_country_id: "Residence Country",
			join_date: "تاريخ الإنضمام",
			days_count: "Days Count",
			return_date: "Return Date",
			employee_notes: "Employee Notes",
			hr_notes: "HR Notes",
			due_date: "Due Date",
			other_passengers: "Other Passengers",
			female: "Female",
			male: "Male",
			age: "Age",
			experts: "Experts",
			nationality: "Nationality",
			expect_salary: "Expect Salary",
			trainer_name: "Trainer Name",
			start_date: "Start Date",
			end_date: "End Date",
			received_at: "Received at",
			reason: "Reason",
			annual: "Annual",
			casual: "Casual",
			sick: "Sick",
			approved_by_id: "Approved by",
			tasks: "Tasks Management",
			can_sell: "Authorized to sell",
			hr_deduction: "HR Deduction",
			substitute_id: "الموظف البديل"
		},
		ar: {
			employees: "الموظفين",
			countries: "الدول",
			cities: "المدن",
			employee_id: "الموظف",
			type: "النوع",
			date: "التاريخ",
			first_name: "الإسم الأول",
			middle_name: "الإسم الأوسط",
			last_name: "الإسم الأخير",
			first_name_ar: "الإسم الأول (عربي)",
			middle_name_ar: "الإسم الأوسط (عربي)",
			last_name_ar: "الإسم الأخير (عربي)",
			attendances: "الحضور والإنصراف",
			departments: "الأقسام",
			evaluations: "التقييمات",
			facts: "الوقائع",
			loans: "السلف",
			installments: "أقساط السلف",
			payables: "الرواتب والمدفوعات",
			tickets: "تذاكر الطيران",
			printing: "المطبوعات",
			vacations: "الأجازات",
			training: "التدريب",
			hiring: "التوظيف",
			company_structure: "هيكل الشركة",
			setting: "الإعدادات",
			general_setting: "الإعدادات العامة",
			titles: "المسميات الوظيفية",
			company: "الشركة",
			personal_portal: "الخدمات الشخصية",
			substitute_id: "الموظف البديل",
			"End of Service": "حساب نهاية الخدمة",
			"My Attendance": "الحضور والإنصراف",
			"My Loans": "السلف والقروض",
			"My Payments": "مدفوعاتي",
			"My Tickets": "تذاكري",
			"My Vacations": "أجازاتي",
			"My Training": "تدريبي",
			"My Installments": "أقساطي",
			"Employees Report": "تقرير الموظفين",
			"Vacations Report": "تقرير الأجازات",
			"Payments Report": "تقرير المدفوعات",
			"Tickets Report": "نقرير التذاكر",
			"Attendances Report": "تقرير الحضور",
			"Facts Report": "تقرير الوقائع",
			"Evaluations Report": "تقرير التقييمات",
			"Hiring Report": "تقرير التوظيف",
			"Training Report": "تقرير التدريب",
			"Loans Report": "تقرير السلفات",
			"Installments Report": "تقرير أقساط السلفات",
			"Company": "الشركة",
			"Personal Portal": "الخدمات الشخصيه",
			"Company Structure": "هيكل الشركة",
			"General Setting": "الإعدادات العامة",
			"HR Reports": "تقارير الموارد البشرية",
			"Setting": "الإعدادات",
			"job_title": "المسمي الوظيفي",
			"Contact Details": "بيانات الإتصال",
			"Salary Details": "تفاصيل الراتب",
			"Identity & Passport": "الهوية وجواز السفر",
			"Job Number": "الرقم الوظيفي",
			residence_city_id: "مدينة الإقامة",
			title_id: "المسمي الوظيفي",
			"Evaluation Details": "تفاصيل التقييم",
			"Employee Information": "معلومات الموظف",
			"leave_of_absences": "اذونات الغياب",
			department_id: "القسم",
			gender: "النوع",
			birthday: "تاريخ الميلاد",
			salary: "الراتب",
			phone: "الهاتف",
			mobile: "الجوال",
			salary_notes: "ملاحظات الراتب",
			transportation_allowance: "بدل التنقل",
			housing_allowance: "بدل السكن",
			other_allowance: "بدلات أخري",
			emergency_contact_name: "إسم الإتصال في حالة الطوارئ",
			emergency_contact_number: "رقم الإتصال في حالة الطوارئ",
			leave_date: "تاريخ ترك العمل",
			identity_number: "رقم الهوية/الإقامة",
			identity_release_date: "تاريخ إصدار الهوية",
			identity_expiry_date: "تاريخ إنتهاء الهوية",
			passport_number: "زقم جواز السفر",
			passport_release_date: "تاريخ إصدار جواز السفر",
			passport_expiry_date: "تاريخ إنتهاء جواز السفر",
			vacations_balance: "رصيد الأجازات",
			vacation_consumption: "المستهلك من رصيد الأجازات",
			Manager: "المستهلك من رصيد الأجازات",
			manager_id: "المدير المباشر",
			Edit: "تعديل",
			birth_city_id: "مدينة الميلاد",
			residence_country_id: "دولة الإقامة",
			birth_country_id: "دولة الميلاد",
			"Employment Information": "المعلومات الوظيفية",
			"Contact Information": "معلومات الإتصال",
			"Emergency Contact Information": "معلومات التواصل للطوارئ",
			"Contract Information": "معلومات التعاقد",
			"ID Documents Information": "معلومات الوثائق الثبوتية",
			"Salary Information": "تفاصيل الراتب",
			join_date: "تاريخ الإنضمام",
			"Employee Documents": "الملفات والوثائق",
			days_count: "عدد الأيام",
			return_date: "تاريخ العودة",
			employee_notes: "ملاحظات الموظف",
			hr_notes: "ملاحظات الموارد البشرية",
			due_date: "تاريخ الإستلام",
			"Search for an employee": "البحث عن موظف",
			"Join date": "تاريخ الإنضمام",
			other_passengers: "المرافقون",
			female: "أنثي",
			male: "ذكر",
			age: "العمر",
			experts: "الخبرة",
			nationality: "الجنسية",
			expect_salary: "الراتب المتوقع",
			trainer_name: "المدرب",
			start_date: "تاريخ البداية",
			end_date: "تاريخ الإنتهاء",
			received_at: "تاريخ الإستيلام",
			reason: "السبب",
			"Full-time": "دوام كامل",
			"Part-time": "دوام جزئي",
			"Fixed-hours": "ساعات ثابتة",
			Consultancy: "إستشاري",
			inbox: "صندوق الوارد",
			'phone-screening': "فحص بالمكالمة",
			'first-interview': "المقابلة الأولي",
			'second-interview': "المقابلة الثانية",
			'not-qualified': "غير مناسب",
			'job-offer': "عرض توظيف",
			rejected: "مرفوض",
			education: "المستوي التعليمي",
			delay: "تأخير",
			leave: "غياب",
			deduction: "خصم",
			incentives: "مكافئات وحوافز",
			other_allowances: "بدلات أخري",
			annual: "سنوية",
			casual: "عارضة",
			sick: "مرضية",
			approved_by_id: "الموافقة بواسطة",
			deadline: "موعد التسليم",
			invoice: "فاتورة",
			receipt_voucher: "سند قبض",
			cash_voucher: "سند صرف",
			other: "أخري",
			work_period: "فترة العمل",
			paid_vacation: " أجازة مدفوعة",
			last_vacation: "أخر أجازة",

			"Overview Report": "نظره عامة",
			"Salaries Total": "مجموع الرواتب",
			"Employees Total": "عدد الموظفين",
			"Employees per Country": "الموظفين حسب الدولة",
			"Employees per Title": "الموظفين حسب المسمي",
			"Employees per Department": "الموظفين حسب القسم",
			"Employees per Joined": "الموظفين حسب ناريخ الإنضمام",
			"Employees per Left": "الموظفين حسب تاريخ المغادرة",
			"Employees per Contract": "الموظفين حسب نوع العقد",
			"Employees per Gender": "الموظفين حسب النوع",
			"Employees per Status": "الموظفين حسب الحالة",
			"Salaries per Department": "الرواتب حسب القسم",
			"Employees per Salary": "الموظفين حسب الراتب",
			"Cant delete main department": "لا يمكن حذف قسم رئيسي",

			"Evaluations Total": "عدد التقيمات",
			"Training per start date": "التدريب بحسب البداية",
			"Tickets Total": "عدد التذاكر",
			"Departments Total": "عدد الأقسام",
			"Fact Total": "عدد الوقائع",
			"Hiring Total": "عدد طلبات التوظيف",
			"Installments Total": "عدد الأقساط",
			"Training Total": "عدد الدورات التدريبة",
			"Vacations Total": "عدد الأجازات",
			"Total Leave of Absences": "عدد أذونات الغياب",
			"Evaluations Per Month": "التقيمات بحسب الشهر",
			"Leave Of Absences per Status": "أذونات الغياب بحسب الحالة",

			tasks: "إدارة المهام",
			"Tasks Report": "تقرير المهام",
			"Tasks per Status": "المهام حسب الحالة",
			"Tasks per Deadline": "المهام حسب موعد الإنتهاء",
			"Tasks per Employee": "المهام بحسب الموضفين",
			Comments: "التعليقات",
			"Add Comments": "إضافة تعليق",

			"You can't delete this employee because he has existing evaluations": "لا يمكن حذف هذا الموظف لانه مرتبط بتقيمات",
			"You can't delete this employee because he has existing payables": "لا يمكن حذف هذا الموظف لانه مرتبط بمدفوعات",
			"You can't delete this employee because he has existing loans": "لا يمكن حذف هذا الموظف لانه مرتبط بسلف",
			"You can't delete this employee because he has existing facts": "لا يمكن حذف هذا الموظف لانه مرتبط بوقائع",
			"You can't delete this employee because he has existing tickets": "لا يمكن حذف هذا الموظف لانه مرتبط بتذاكر",
			"You can't delete this employee because he has existing attendances": "لا يمكن حذف هذا الموظف لانه مرتبط بسجلات حضور وإنصراف",
			"You can't delete this employee because he has existing installments": "لا يمكن حذف هذا الموظف لانه مرتبط بأقساط سلف",

			supervisors_ids: "المشرفين",
			participants_ids: "المشاركين",
			assistants_ids: "المساعدين",
			sequence_from: "بداية التسلسل",
			sequence_to: "نهاية التسلسل",
			transfer_surety_date: "تاريخ نقل الكفالة",
			can_sell: "التصريح بالبيع",
			Yes: "نعم",
			No: "لا",
			receipt_of_work: "إستلام العمل",
			transfer_to_another_department: "النقل لفرع إخر",
			change_of_job_title: "تغيير الوظيفة",
			attachments: "المرفقات",
			cash: "نقدي",
			bank_transfer: "تحويل بنكي",
			release_date: "تاريخ الإصدار",
			expiration_date: "تاريخ الإنتهاء",
			"Add Comment": "إضافة تعليق",
			Comment: "التعليق",
			bank_account: "الحساب البنكي",
			bank_code: "كود البنك",
			hr_deduction: "خصم وظيفي",
			"Prepare Salaries": "إعداد الرواتب",
			employee: "الموظف",
			"Bank File": "ملف البنك",
			hired: "تم التعيين",
			linked_user: "تابع للعضو",
			"final approval": "موافقه المدير العام",
			"preapproved-1": "موافقه الHR",
			"preapproved-2": "موافقه الحسابات",
			"preapproved-3": "موافقه إضافية ١",
			"preapproved-4": "موافقه إضافية ٢",
			"preapproved-5": "موافقه إضافية ٣",
			Notes: "ملاحظات",
			"Add Note": "إضافة ملاحظات",
			vacation_length: "مدة الأجازة"
		}
	},
	navigation: {
		menu: [
			{
				id: "hr",
				appID: "hr",
				name: "hr",
				order: 2,
				icon: "icon-menu-02",
				link: "/hr/reports/overview",
				pages: [
					{ name: "Overview", link: "/hr/reports/overview", className: "overview", permission: "reports.overview" },
					{ name: "Company Structure", link: "/hr/structure", permission: "structure" },
					{ name: "employees", link: "/hr/crud/employees", permission: "employees" },
					{ name: "vacations", link: "/hr/crud/vacations", permission: "vacations" },
					{ name: "payables", link: "/hr/crud/payables", permission: "payables" },
					{ name: "tickets", link: "/hr/crud/tickets", permission: "tickets" },
					{ name: "attendances", link: "/hr/crud/attendances", permission: "attendances" },
					{ name: "leave_of_absences", link: "/hr/crud/leave_of_absences", permission: "leave_of_absences" },
					{ name: "facts", link: "/hr/crud/facts", permission: "facts" },
					{ name: "evaluations", link: "/hr/crud/evaluations", permission: "evaluations" },
					{ name: "hiring", link: "/hr/crud/hiring", permission: "hiring" },
					{ name: "printing", link: "/hr/crud/printing", permission: "printing" },
					{ name: "training", link: "/hr/crud/training", permission: "training" },
					{ name: "End of Service", link: "/hr/end_of_service", permission: "end_of_service" },
					{ name: "loans", link: "/hr/crud/loans", permission: "loans" },
					// todo: {name: "installments", link: "/hr/crud/installments", permission: "installments },
					{ name: "general_setting", link: "/hr/setting", permission: "setting" },
					{ name: "HR Reports", link: "/hr/reports/employees", permission: "reports.overview" },
					{ name: "إعدادات المستخدمين للموارد", link: "/hr/crud/users", permission: "users" },
					{ name: "إعدادات الوصول للموارد", link: "/hr/crud/roles", permission: "roles" },
				]
			},
			{
				id: "tasks",
				order: 20,
				name: "tasks",
				icon: "icon-menu-10",
				link: "/hr/reports/tasks",
				pages: [
					{ name: "Overview", link: "/hr/reports/tasks", className: "overview" },
					{ name: "قائمة المهام", link: "/hr/crud/tasks" },
					{ name: "مهامي", link: "/personal/tasks" },
					{ name: "إضافة مهمة جديدة", link: "/hr/crud/tasks/create" },
				]
			}
		]
	}
}

