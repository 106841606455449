import "core-js/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import Portal from './Portal';
import * as serviceWorker from './serviceWorker';

// global css
import "normalize.css";
import "bootstrap-4-grid/css/grid.min.css";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import "@blueprintjs/datetime/lib/css/blueprint-datetime.css";

ReactDOM.render(<Portal/>, document.getElementById('root'));

serviceWorker.unregister();
