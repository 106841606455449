import React from 'react';
import {createStore, combineReducers, applyMiddleware} from "redux";
import { Provider } from 'react-redux';
import {Route} from "react-router-dom";
import thunk from 'redux-thunk';
import createSagaMiddleware from "redux-saga";
import {all} from "redux-saga/effects";
import * as t from "counterpart";
import reducer from "./reducer";
import apps from "./apps";
import LayoutInit, {Layout} from "./components/Layout/init";
import Static from "./components/Static/init";
import Profile from "./components/Profile";
import {Callout} from "@blueprintjs/core";

let reducers = {portal: reducer},
	routes = [<Route key="profile" exact path="/profile" component={Profile} />],
	navigation = [],
	translations = {},
	sagas = [];

// setup the apps
[LayoutInit, Static, ...apps].forEach(app => {
	routes = [...routes, ...app.routes];
	reducers = {...reducers, ...app.reducers};
	sagas = [...sagas, ...(app.sagas || [])];
	navigation = [...navigation, ...app.navigation.menu];
	translations.en = {...translations.en, ...app.i18n.en};
	translations.ar = {...translations.ar, ...app.i18n.ar};
});

// setup redux store

const saga = createSagaMiddleware();
const store = createStore(combineReducers(reducers), applyMiddleware(thunk, saga));

saga.run(function* watcherSaga() {
	yield all(sagas);
});

// setup translations
t.registerTranslations("en", translations.en);
t.registerTranslations("ar", translations.ar);
t.setLocale("ar");
t.setMissingEntryGenerator(function(key) {return key.split(".")[1]});

//
class Portal extends React.Component
{
	constructor(props)
	{
		super(props);

		const windowID = (new Date).toISOString()+(Math.random()*1e32).toString(36);
		localStorage.setItem("portal_window_id", windowID)
		this.state = {windowID};
	}

	componentDidMount() {
		window.addEventListener('storage', ({key, newValue}) => {
			if(key == 'portal_window_id') {
				if(newValue && newValue != this.state.windowID) {
					this.setState({abandoned: true});
				}
			}
		})
	}

	render()
	{
		return (
			<Provider store={store}>
				{!this.state.abandoned ?
					<Layout routes={routes} navigation={navigation} /> :
					<div id="portal-loading">
						<img src="/assets/bh_logo.png" height="100" alt=""/>
						<Callout intent="warning" title="تم الإنتقال لنافذة جديدة" />
					</div>
				}
			</Provider>
		);
	}
}

export default Portal;
