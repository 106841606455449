import axios from "axios";
import IO from 'socket.io-client';
import * as t from "counterpart";
import storage from "./storage";

export let baseURL = process.env.REACT_APP_API_BASE_URL.replace("%HOST%", window.location.protocol + '//' + window.location.host.split(":")[0]);
if(window.location.hostname === "app.binhamood.co"){
	baseURL = "https://api.binhamood.co";
}

// http
export default function (url, request)
{
	let base = baseURL;
	let app = url.substr(0, 3);
	if(window.location.host === 'localhost:3000' &&  (app === '/hr' || app === 'hr/')) {
		base = "https://portal-api.uplift.plus/hr"; // "http://localhost:8001";
		url = url.replace(/^.{3}/g, '');
	}

	// set initial setting
	request = {
		url: url,
		method: "GET",
		data: {},
		headers: {},
		responseType: "json",
		result: () => {},
		error: () => {},
		...request
	};

	let isDownload = false;
	if(request.blob) {
		isDownload = true;
		request.responseType = "blob";
	}

	// convert data object to form data
	let formData = new FormData();
	for (let key in request.data)
	{
		let value = request.data[key];
		if(value) {
			const isArray = value instanceof Array;
			if(typeof value === "object" && !isArray && !(value instanceof File) && value !== null) {
				value = JSON.stringify(value)
			}

			if(isArray)
			{
				value.forEach((v, i) => {
					if(v instanceof Object && v !== null && !(v instanceof File)) {
						Object.entries(v).forEach(([k2, v2]) =>
							formData.append(key+"["+i+"]["+k2+"]", v2)
						)
					} else {
						formData.append(key+"[]", v)
					}
				});
			} else {
				formData.append(key, value);
			}
		}
	}

	// handle put and form-data issue
	if(request.method === "PUT") {
		request.method = "POST";
		formData.append("_method", "PUT");
	}

	// make the request
	return axios({
		baseURL: base,
		url: request.url,
		method: request.method,
		data: formData,
		// timeout: 10000,
		responseType: request.responseType,
		headers: {
			"Authentication": storage("access_token"),
			"Content-Type": "application/x-www-form-urlencoded",
			"Language": t.getLocale(),
			"As_Admin": true, // todo: change to Interface: admin
			...request.headers
		}
	})
		.then(response => {
			if(isDownload) {
				let link = document.createElement('a');
				link.href = window.URL.createObjectURL(response.data);
				link.download = request.blobName || "Download";
				link.click();
				return
			}

			if(request.raw) {
				request.result(response.data);
				return;
			}

			const data = response.data || {};
			if(data.success === true) {
				request.result(data);
			} else {
				const error = data.error || data.message || "Unknown Server Error";
				request.error(error);
			}
		})
		.catch(error => {
			let msg = "";
			if (error.response)
			{
				if(isDownload) {
					msg = error.response.statusText;
				}
				else
				{
					if(error.response.status === 401) {
						msg = "Unauthorized";
					}
					else
					{
						const data = (error.response.data || {});
						msg = data.message || data.error || "Unknown Server Error";
						if (typeof msg === 'object') {
							msg = msg.message || msg.error || "Unknown Server Error";
						}
					}
				}
			}
			else if (error.request) {
				msg = "Unable to connect to the server, Check your internet connection";
			} else {
				msg = error.message;
			}

			request.error(t(msg || "Request Error"), error);
		});
}

// websocket
export function webSocket(url)
{
	url = baseURL.replace("http", "ws")+"/"+url+"?authentication="+storage("access_token");
	return new WebSocket(url);
}

// Socket IO
export function socketIO(url)
{
	return new IO(baseURL, {
		path: url+"/socket.io/",
		withCredentials: false,
		query: "authentication="+storage("access_token"),
		reconnectionDelay: 10000,
		reconnectionAttempts: 20
	});
}
