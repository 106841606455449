import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import api from "../../utilities/API";
import storage from "../../utilities/storage";
import {FormGroup, InputGroup, Button, Callout} from "@blueprintjs/core";

const defaultImage =  "/user_image.png";

const Spinner = "div";

export default function()
{
	const dispatch = useDispatch();
	const user = useSelector(state => state.portal.user || {});
	let [form, setForm] = useState(user);
	const [feedback, setFeedback] = useState({});

	//
	const onChange = ({target}) => {
		let v = target.value;
		if(target.type === "checkbox") {
			v = target.checked ? "yes" : "";
		}
		else if(target.type === "file") {
			v = target.files[0];
			form.preview = v ? URL.createObjectURL(v) : defaultImage;
		}
		setForm({...form, [target.name]: v});
	};

	//
	const onSubmit = () =>
	{
		setFeedback({loading: true});

		api("/auth/user", {
			data: form,
			method: "POST",

			result: ({data}) =>
			{
				// dispatch({type: "PORTAL_SET_USER", payload: {...user, ...data.user}});
				setFeedback({alert: "تم تحديث المعلومات الشخصيه بنجاح", success: true});
			},

			error: (error, {response}) =>
			{
				if((response || {}).status === 422) {
					error = response.data
				}

				setFeedback({alert: error});
			},
		});
	};

	//
	let alert = feedback.alert;
	let validation = {};
	if(alert) {
		if(typeof alert === "object") {
			validation = alert;
			alert = "يجب تعبئة كافة الحقول ببيانات صحيحة";
		}

		alert = <Callout intent={feedback.success ? "success" : "danger"}>{alert}</Callout>
	}

	// set the user info as the defaults
	const preview = form.image_file || user.image || defaultImage;
	form = {preview, ...user, ...form};

	return (
		<div id="profile-page" className=" page-body">
			<h1>
				حسابك الشخصي
				{!user.id && <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
			</h1>
			<h2>إدارة معلوماتك الشخصية</h2>

			{user.id &&
			<div className="col-md-6 offset-md-3">
				{alert}

				<FormGroup controlId="firstName">
					<i className="fa fa-user" />
					<InputGroup intent={(validation["first_name"] ? "danger" : "")} placeholder="الإسم الأول" name="first_name" value={form.first_name} onChange={onChange}/>
					<div className="invalid-feedback">{validation["first_name"]}</div>
				</FormGroup>

				<FormGroup intent={(validation["last_name"] ? "danger" : "")} controlId="lastName">
					<i className="fa fa-user" />
					<InputGroup type="text" placeholder="الإسم الأخير" name="last_name" value={form.last_name} onChange={onChange}/>
					<div className="invalid-feedback">{validation["last_name"]}</div>
				</FormGroup>

				<FormGroup intent={(validation["email"] ? "danger" : "")} controlId="registerEmail">
					<i className="fa fa-at" />
					<InputGroup type="text" placeholder="البريد الإلكتروني" name="email" value={form.email} onChange={onChange}/>
					<div className="invalid-feedback">{validation["email"]}</div>
				</FormGroup>

				<FormGroup intent={(validation["mobile"] ? "danger" : "")} controlId="mobile">
					<i className="fa fa-mobile" />
					<InputGroup type="text" placeholder="الهاتف الجوال" name="mobile" value={form.mobile} onChange={onChange}/>
					<div className="invalid-feedback">{validation["mobile"]}</div>
				</FormGroup>

				<FormGroup intent={(validation["password"] ? "danger" : "")} controlId="registerPassword">
					<i className="fa fa-lock" />
					<InputGroup type="password" placeholder="كلمة مرور جديدة" name="password" value={form.password} onChange={onChange}/>
					<div className="invalid-feedback">{validation["password"]}</div>
				</FormGroup>

				<label className="user-image">
					<div>الصورة الشخصية</div>
					{form.preview && <img src={form.preview} alt="" />}
					<br/>
					<input type="file" name="image_file" onChange={onChange} />
				</label>
				<br/>
				<br/>

				<Button onClick={onSubmit} disabled={feedback.loading} intent="primary">
					{feedback.loading && <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />}
					تحديث البيانات
				</Button>
			</div>}
		</div>
	)
}