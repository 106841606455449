import React from "react";
import Select from "react-select";
import {Checkbox, HTMLTable} from "@blueprintjs/core";
import "./style/Invoice.scss";

function SalesInvoice()
{
	const invoice = {
		isSupplier: false,
		selectedBranch: 0,
	};

	const clientTypes = [
		{value: "client", label: "عميل"}, {value: "supplier", label: "مود"}
	];

	const branches = [
		{value: "client", label: "عميل"}, {value: "supplier", label: "مود"}
	];

	return (
		<div id="accounting-sales-invoice" className="fluid-container">
			<h1>فاتورة مبيعات مباشرة</h1>

			<div className="row">
				<div className="col-sm-4">
					<Checkbox checked={invoice.isSupplier} onChange={() => {}} alignIndicator="right" style={{float: "left", margin: "7px 10px 0 0"}}>مورد</Checkbox>
					<Select placeholder="العميل" options={clientTypes}/>
				</div>
				<div className="col-sm-4">
					<Select placeholder="الفرع" options={branches}/>
				</div>
				<div className="col-sm-4">
					<Select placeholder="الدفعة" options={branches}/>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-4">
					<Select placeholder="التسعير" options={branches}/>
				</div>
				<div className="col-sm-4">
					<Select placeholder="التاريخ" options={branches}/>
				</div>
				<div className="col-sm-4">
					<Select placeholder="مراكز التكلفة" options={branches}/>
				</div>
			</div>

			<div className="row">
				<div className="col-sm-4">
					<Select placeholder="المندوب" options={branches}/>
				</div>
				<div className="col-sm-4">
					<Select placeholder="خصم العميل" options={branches}/>
				</div>
				<div className="col-sm-4">
					<Select placeholder="الحد الإتماني" options={branches}/>
				</div>
			</div>

			<h2>الأصناف</h2>
			<HTMLTable width="100%" striped>
				<thead>
				<tr>
					<th>كود الصنف المخزني</th>
					<th>وصف الصنف المخزني</th>
					<th>الكمية </th>
					<th>الكمية المتاحة</th>
					<th>السعر قبل الضريبة</th>
					<th>نسبة الخصم</th>
					<th>قيمة الخصم</th>
					<th>الإجمالي</th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Blueprint</td>
					<td>CSS framework and UI toolkit</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
				</tr>
				<tr>
					<td>Blueprint</td>
					<td>CSS framework and UI toolkit</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
					<td>Sass, TypeScript, React</td>
				</tr>
				</tbody>
			</HTMLTable>

			<h2>المقبوضات</h2>
			<HTMLTable width="100%" striped>
				<thead>
				<tr>
					<th>كود الصنف المخزني</th>
					<th>وصف الصنف المخزني</th>
					<th>الكمية </th>
				</tr>
				</thead>
				<tbody>
				<tr>
					<td>Blueprint</td>
					<td>CSS framework and UI toolkit</td>
					<td>Sass, TypeScript, React</td>
				</tr>
				<tr>
					<td>Blueprint</td>
					<td>CSS framework and UI toolkit</td>
					<td>Sass, TypeScript, React</td>
				</tr>
				</tbody>
			</HTMLTable>
		</div>
	);
}

export default SalesInvoice;