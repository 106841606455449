import React, {Component} from 'react';
import "./style.scss";

const externalApps = {
	purchases: {
		"new_order": "/purchasing/po_entry_items.php?NewOrder=Yes",
		"03": "/purchasing/inquiry/po_search.php?",
		"04": "/purchasing/po_entry_items.php?NewGRN=Yes",
		"05": "/purchasing/po_entry_items.php?NewInvoice=Yes",
		"06": "/purchasing/supplier_payment.php?",
		"07": "/purchasing/supplier_invoice.php?New=1",
		"08": "/purchasing/supplier_credit.php?New=1",
		"09": "/purchasing/allocations/supplier_allocation_main.php?",
		"orders_search": "/purchasing/inquiry/po_search_completed.php?",
		"11": "/purchasing/inquiry/supplier_inquiry.php?",
		"12": "/purchasing/inquiry/supplier_allocation_inquiry.php?",
		"13": "/reporting/reports_main.php?Class=1&REP_ID=201",
		"14": "/purchasing/manage/suppliers.php?",
		"15": "/purchasing/manage/contract_suppliers.php",
		"16": "/purchasing/supp_credit_debit.php?New=1",
		"17": "/purchasing/supp_credit_debit.php?New=1&debit=1",
		"18": "/purchasing/inquiry/supplier_residual_inquiry.php?",
		"19": "/purchasing/inquiry/supplier_delivery_inquiry.php",
		"20": "/purchasing/inquiry/supplier_invocie_inquiry.php",
		"21": "/purchasing/po_collect_recevie_items.php?NewGRN=Yes",
		"22": "/purchasing/supplier_credit.php?New=1&returnInvoice=1",
		"23": "/purchasing/inquiry/supplier_bouns_inquiry.php",
		"24": "/purchasing/po_entry_items.php?NewInvoice=Yes&returnInvoice=1",
		"25": "/purchasing/inquiry/supplier_statement_account.php",
		"26": "/purchasing/inquiry/supplier_invocie_inquiry.php?changes=1",
		"27": "/admin/short_custs.php?type=20",
		"28": "/admin/short_custs.php?type=18",
		"29": "/admin/short_custs.php?type=21",
		"30": "/admin/short_custs.php?type=25",
		"31": "/purchasing/previous_credit.php?New=1",
		"32": "/purchasing/previous_credit.php?New=1&notReceived=1",
	},

	inventory: {
		"02": "/inventory/transfers.php?NewTransfer=1",
		"03": "/inventory/adjustments.php?NewAdjustment=1",
		"04": "/inventory/inquiry/stock_movements.php?",
		"05": "/inventory/inquiry/stock_status.php?",
		"06": "/reporting/reports_main.php?Class=2&REP_ID=301",
		"07": "/inventory/manage/items.php?",
		"08": "/inventory/manage/item_codes.php?",
		"09": "/inventory/manage/sales_kits.php?",
		"10": "/inventory/manage/item_categories.php?",
		"11": "/inventory/manage/locations.php?",
		"12": "/inventory/manage/item_units.php?",
		"13": "/inventory/reorder_level.php?",
		"14": "/inventory/prices.php?",
		"15": "/inventory/purchasing_data.php?",
		"16": "/inventory/cost_update.php?",
		"17": "/inventory/cost_items.php?",
		"18": "/inventory/inventory_reports.php?",
		"19": "/inventory/manage/manufacturers.php?",
		"20": "/inventory/inquiry/approve_transfar.php",
		"21": "/inventory/show_items_requests.php",
		"22": "/inventory/new_items_requests.php",
		"23": "/inventory/save_items.php",
		"24": "/inventory/drivers_trucks_in_out.php",
		"25": "/inventory/adjustments.php?NewAdjustment=1&deadStock=1",
		"26": "/inventory/inquiry/adjustment_inquiry.php",
		"27": "/inventory/inquiry/save_item_inquiry.php",
		"29": "/inventory/inquiry/location_stock_inquiry.php",
		"30": "/inventory/inquiry/stagnant_report.php",
		"31": "/inventory/manage/attribute_sets.php",
		"32": "/inventory/attribute_groups.php",
		"33": "/admin/short_custs.php?type=16",
		"34": "/inventory/inquiry/location_amount_inquiry.php",
		"35": "/inventory/inquiry/location_stock_inactive_inquiry.php",
		"36": "/inventory/change_cost.php",
		"37": "/inventory/inquiry/stock.php",
	},

	finance: {
		"01": "/gl/gl_bank.php?NewPayment=Yes",
		"02": "/gl/gl_bank.php?NewDeposit=Yes",
		"03": "/gl/bank_transfer.php?",
		"04": "/gl/gl_journal.php?NewJournal=Yes",
		"05": "/gl/gl_budget.php?",
		"06": "/gl/bank_account_reconcile.php?",
		"07": "/gl/accruals.php?",
		"08": "/gl/inquiry/journal_inquiry.php?",
		"09": "/gl/inquiry/gl_account_inquiry.php?",
		"10": "/gl/inquiry/bank_inquiry.php?",
		"11": "/gl/inquiry/tax_inquiry.php?",
		"12": "/gl/inquiry/gl_trial_balance.php?",
		"13": "/gl/inquiry/balance_sheet.php?",
		"14": "/gl/inquiry/profit_loss.php?",
		"15": "/reporting/reports_main.php?Class=5",
		"16": "/reporting/reports_main.php?Class=6",
		"17": "/gl/manage/bank_accounts.php?",
		"18": "/gl/manage/gl_quick_entries.php?",
		"19": "/admin/tags.php?type=account",
		"20": "/gl/manage/currencies.php?",
		"21": "/gl/manage/exchange_rates.php?",
		"22": "/gl/manage/gl_accounts.php?",
		"23": "/gl/manage/gl_account_types.php?",
		"24": "/gl/manage/gl_account_classes.php?",
		"25": "/gl/manage/close_period.php?",
		"26": "/gl/manage/revaluate_currencies.php?",
		"27": "/admin/tags.php?type=dimension",
		"28": "/dimensions/dimension_entry.php?",
		"29": "/dimensions/inquiry/search_dimensions.php?outstanding_only=1",
		"30": "/dimensions/inquiry/search_dimensions.php?",
		"31": "/reporting/reports_main.php?Class=4",
		"32": "/gl/inquiry/branch_collections.php",
		"34": "/gl/bank_transfer.php?receipt=1",
		"35": "/gl/manage/percent_employees.php",
		"36": "/gl/manage/bank_accounts.php?private=1",
		"37": "/gl/gl_bank.php?NewPayment=yes&private=1",
		"38": "/gl/gl_bank.php?NewDeposit=Yes&private=1",
		"39": "/gl/inquiry/bank_inquiry.php?private=1",
		"40": "/gl/manage/payment_rent.php",
		"41": "/gl/inquiry/payment_rent_inquiry.php?",
		"42": "/gl/initiative_payment.php?NewJournal=1",
		"43": "/gl/inquiry/initiative_payment_inquiry.php",
		"44": "/gl/inquiry/payroll.php?action=due",
		"45": "/gl/inquiry/payroll.php?action=transfer",
		"46": "/gl/inquiry/payroll.php?action=pay",
	},

	sales: {
		"01": "/sales/sales_order_entry.php?NewQuotation=Yes",
		"02": "/sales/sales_order_entry.php?NewOrder=Yes",
		"03": "/sales/sales_order_entry.php?NewDelivery=0",
		"04": "/sales/sales_order_entry.php?NewInvoice=0",
		"05": "/sales/inquiry/sales_orders_view.php?OutstandingOnly=1",
		"06": "/sales/inquiry/sales_deliveries_view.php?OutstandingOnly=1",
		"07": "/sales/inquiry/sales_orders_view.php?PrepaidOrders=Yes",
		"08": "/sales/inquiry/sales_orders_view.php?DeliveryTemplates=Yes",
		"09": "/sales/inquiry/sales_orders_view.php?InvoiceTemplates=Yes",
		"10": "/sales/create_recurrent_invoices.php?",
		"11": "/sales/customer_payments.php?",
		"12": "/sales/credit_note_entry.php?NewCredit=Yes",
		"13": "/sales/allocations/customer_allocation_main.php?",
		"14": "/sales/inquiry/sales_orders_view.php?type=32",
		"15": "/sales/inquiry/sales_orders_view.php?type=30",
		"16": "/sales/inquiry/customer_inquiry.php?",
		"17": "/sales/inquiry/customer_allocation_inquiry.php?",
		"18": "/sales/manage/customers.php?",
		"19": "/reporting/reports_main.php?Class=0",
		"20": "/sales/manage/customer_branches.php?",
		"21": "/sales/manage/sales_groups.php?",
		"22": "/sales/manage/recurrent_invoices.php?",
		"23": "/sales/manage/sales_types.php?",
		"24": "/sales/manage/sales_people.php?",
		"25": "/sales/manage/sales_areas.php?",
		"26": "/sales/manage/credit_status.php?",
		"27": "/sales/inquiry/supplier_pass_due_date.php",
		"28": "/sales/sales_debit_credit.php?NewJournal=Yes",
		"29": "/sales/sales_debit_credit.php?NewJournal=Yes&debit=1",
		"30": "/sales/inquiry/salesman_commission_inquiry.php",
		"31": "/sales/inquiry/salesman_return_inquiry.php",
        "32": "/sales/inquiry/salesman_invoice_inquiry.php",
		"33": "/sales/manage/customers_query.php",
		"34": "/sales/inquiry/salesman_collection_inquiry.php",
		"35": "/sales/inquiry/discount_invoice_inquiry.php",
		"36": "/sales/inquiry/daily_sales_inquiry.php",
		"37": "/sales/sales_order_entry.php?NewInvoice=0&initiative=1",
		"38": "/sales/inquiry/item_most_return_inquiry.php",
		"39": "/sales/credit_note_entry.php?NewCredit=Yes&initiative=1",
		"40": "/admin/short_custs.php?type=10",
		"41": "/admin/short_custs.php?type=11",
		"42": "/admin/short_custs.php?type=13",
		"43": "/admin/short_custs.php?type=30",
		"44": "/admin/short_custs.php?type=32",
		"45": "/sales/previous_credit.php?NewCredit=yes",
		"46": "/sales/inquiry/customer_late_invocie.php",
		"47": "/sales/previous_credit.php?NewCredit=yes&initiative=1",
		"48": "/sales/customer_payments.php?credit=1",
		"49": "/sales/manage/sales_people.php"
	},

	fixed_assets: {
		"01": "/purchasing/po_entry_items.php?NewInvoice=Yes&FixedAsset=1",
		"02": "/inventory/transfers.php?NewTransfer=1&FixedAsset=1",
		"03": "/inventory/adjustments.php?NewAdjustment=1&FixedAsset=1",
		"04": "/sales/sales_order_entry.php?NewInvoice=0&FixedAsset=1",
		"05": "/fixed_assets/process_depreciation.php",
		"06": "/inventory/inquiry/stock_movements.php?FixedAsset=1",
		"07": "/fixed_assets/inquiry/stock_inquiry.php?",
		"08": "/reporting/reports_main.php?Class=7",
		"09": "/inventory/manage/items.php?FixedAsset=1",
		"10": "/inventory/manage/locations.php?FixedAsset=1",
		"11": "/inventory/manage/item_categories.php?FixedAsset=1",
		"12": "/fixed_assets/fixed_asset_classes.php",
		"13": "/gl/gl_bank.php?NewPayment=Yes&fixed=1",
		"14": "/gl/inquiry/fixedasset_bank_inquiry.php?",
	},

	maintenance: {
		"01": "/maintenance/maintenance_men.php",
		"02": "/maintenance/maintenance_requests.php",
		"03": "/maintenance/requests_inquiry.php",
		"04": "/inventory/transfers.php?NewTransfer=1&workshop=1",
		"05": "/purchasing/po_entry_items.php?NewInvoice=Yes&workshop=1",
		"06": "/sales/sales_order_entry.php?NewInvoice=0&workshop=1",
		"07": "/inventory/manage/locations.php?workshop=1",
		"08": "/inventory/inquiry/stock_movements.php?workshop=1",
		"09": "/inventory/adjustments.php?NewAdjustment=1&out_stock=1",
		"10": "/inventory/inquiry/adjustment_inquiry.php?out_stock=1",
	},

	crm: {
		/*"01": "/modules/KvcodesCRM/estimates.php?NewEstimate=Yes",
		"02": "/modules/KvcodesCRM/manage/leads.php",
		"03": "/modules/KvcodesCRM/manage/attachments.php",
		"04": "/modules/KvcodesCRM/tasks/",
		"05": "/modules/KvcodesCRM/estimates.php?NewProposal=Yes",
		"06": "/modules/KvcodesCRM/invoice.php?NewInvoice=Yes",
		"07": "/modules/KvcodesCRM/manage/opportunities.php",
		"08": "/modules/KvcodesCRM/projects/",
		"09": "/modules/KvcodesCRM/inquires/reminders.php",
		"10": "/modules/KvcodesCRM/inquires/reminders.php?medium=1",
		"11": "/modules/KvcodesCRM/inquires/customer_inquiry.php",
		"12": "/modules/KvcodesCRM/inquires/supplier_inquiry.php",
		"13": "/modules/KvcodesCRM/inquires/proposal_estimate_inquiry.php?type=33",
		"14": "/modules/KvcodesCRM/inquires/proposal_estimate_inquiry.php?type=34",
		"15": "/modules/KvcodesCRM/inquires/reminders.php?medium=2",
		"16": "/modules/KvcodesCRM/inquires/opportunities_inquiry.php",
		"17": "/modules/KvcodesCRM/inquires/leads_inquiry.php",
		"18": "/modules/KvcodesCRM/projects/inquiry.php",
		"19": "/modules/KvcodesCRM/tasks/inquiry.php",
		"20": "/modules/KvcodesCRM/reports/crm_reports.php?Class=11&REP_ID=120",
		"21": "/modules/KvcodesCRM/manage/users.php",
		"22": "/modules/KvcodesCRM/manage/customers.php",
		"23": "/modules/KvcodesCRM/manage/customer_branches.php",
		"24": "/modules/KvcodesCRM/manage/suppliers.php",
		"25": "/modules/KvcodesCRM/manage/email_templates.php",
		"26": "/modules/KvcodesCRM/import_export.php",
		"27": "/modules/KvcodesCRM/backups.php",
		"28": "/modules/KvcodesCRM/manage/crm_settings.php",
		"29": "/modules/KvcodesCRM/PickType.php",
		"30": "/modules/KvcodesCRM/picklists.php",
		"31": "/sales/customer_facts_all.php",
		"32": "/sales/customer_facts.php"*/
	},
	setting: {
		"1": "/admin/company_preferences.php?",
		"2": "/admin/users.php?",
		"3": "/admin/security_roles.php?",
		"4": "/admin/display_prefs.php?",
		"5": "/admin/forms_setup.php?",
		"6": "/taxes/tax_types.php?",
		"7": "/taxes/tax_groups.php?",
		"8": "/taxes/item_tax_types.php?",
		"9": "/admin/gl_setup.php?",
		"10": "/admin/fiscalyears.php?",
		"11": "/admin/print_profiles.php?",
		"12": "/admin/payment_terms.php?",
		"13": "/admin/shipping_companies.php?",
		"14": "/sales/manage/sales_points.php?",
		"15": "/admin/printers.php?",
		"16": "/admin/crm_categories.php?",
		"17": "/admin/void_transaction.php?",
		"18": "/admin/view_print_transaction.php?",
		"19": "/admin/attachments.php?filterType=20",
		"20": "/admin/system_diagnostics.php?",
		"21": "/admin/backups.php?",
		"22": "/admin/create_coy.php?",
		"23": "/admin/inst_lang.php?",
		"24": "/admin/inst_module.php?",
		"25": "/admin/inst_theme.php?",
		"26": "/admin/inst_chart.php?",
		"27": "/admin/inst_upgrade.php?",
		"28": "/admin/remove_transaction.php?",
		"29": "/admin/close_periods.php?",
		"30": "/admin/history.php",
		"31": "/reports/",
		"32": "/admin/move_profit.php",
	}
};

// set base url // todo: get from env
let baseURL = process.env.REACT_APP_ACCOUNTING_BASE;
if(window.location.hostname === "portal-admin2.uplift.plus") {
	baseURL = "https://portal-admin2.uplift.plus/accounting";
} else if(window.location.hostname === "portal-admin2.uplift.plus"){
	baseURL = "https://portal-admin2.uplift.plus/accounting";
} else if(window.location.hostname === "app.binhamood.com.sa"){
	baseURL = "https://app.binhamood.com.sa/accounting";
} else if(window.location.hostname === "app.binhamood.co"){
	baseURL = "https://app.binhamood.co/accounting";
} else if(window.location.hostname === "app.binhamood.co"){
	baseURL = "https://app.binhamood.co/accounting";
} else if(window.location.hostname === "app.bhstore.com.sa"){
	baseURL = "https://app.bhstore.com.sa/accounting";
} else if(window.location.hostname === "localhost"){
	baseURL = "https://portal-admin2.uplift.plus/accounting";
}

function setFrameHeight()
{
	const iframe = document.getElementById("accounting-frame");
	if(iframe) {
		try {
			const body = iframe.contentWindow.document.body;
			if(body) {
				let h = body.scrollHeight;
				iframe.style.height = (h < 500 ? "100%" : (h + 50)+"px");
			}
		} catch (e) {
			console.log("Accounting: ", e.message)
		}
	}
}

class Accounting extends Component
{
	//
	constructor(props)
	{
		super(props);

		const {app, action} = this.props.match.params;
		this.state = {
			url: (externalApps[app] || {})[action]
		};
	}

	//
	render()
	{
		const url = this.state.url;
		return <div id="accounting-app">
			{url && <iframe id="accounting-frame" src={baseURL+url} onLoad={setFrameHeight} frameBorder="0" width="100%" height="100%" title=" " />}
		</div>;
	}
}

export default Accounting;
