export default {
    en: {
        commerce: "Commerce",
        addresses: "Addresses",
        attributes: "Attributes",
        brands: "Brands",
        carts: "Carts",
        categories: "Categories",
        favourites: "Favourites",
        orders: "Orders",
        productFaqs: "Product FAQs",
        products: "Products",
        reviews: "Reviews",
        sets: "Product Types",
        stores: "Stores",
        city_id: "City",
        brand_id: "Brand",
        product_id: "Product",
        address_id: "Address",
        attributes_ids: "Attributes",
        attributes_set_id: "Product Type",
        store_id: "Sore",
        required: "Required",
        searchable: "Searchable",
        display_setting: "Display Setting",
        default_options: "Default Products Options",
        product_options: "Product Options",
        district_name: "District Name",
        building_name: "Building Name",
        building_type: "Building Type",
        building_number: "Building Number",
        nearby_landmarks: "Nearby _landmarks",
        language: "Language",
        language_code: "Language",
        store_code: "Store Code",
        key: "Key",
        label: "Label",
        quantity: "Quantity",
        image: "Image",
        thumbnail: "Thumbnail",
        icon: "Icon",
        question: "Question",
        answer: "Answer",
        payment_id: "Payment ID",
        payment_method: "Payment Method",
        payment_type: "Payment Type",
        shipping_method_id: "Shipping Method",
        views: "Views",
        reviewers: "Reviewers",
        stars: "Stars",
        price: "Price",
        total: "Total",
        special_price: "Special Price",
        special_from: "Special from",
        special_to: "Special to",
        color: "Color",
        rate: "Rate",
        confirmed: "Confirmed",
        unconfirmed: "Unconfirmed",
        method_name: "Method Name",
        displayed_name: "Displayed Name",
        shippingMethods: "Shipping Methods",
        coupons: "Coupons",
        coupon_code: "Coupon Code",
        discount: "Discount",
        order_amount: "Order Amount",
        capacity: "Capacity",
        use_per_user: "Use per User",
        usage: "Usage",
        date_from: "Date From",
        date_to: "Date To",
        category_ids: "Category IDs",
        sellers: "Sellers",
    },
    ar: {
        "Commerce Management": "إدارة المتجر الألكتروني",
        commerce: "التجارة الإلكترونية",
        addresses: "العناوين",
        attributes: "الخصائص",
        brands: "العلامات التجارية",
        carts: "عربة التسوق",
        categories: "التصنيفات",
        favourites: "المفضلة",
        orders: "طلبات الشراء",
        productFaqs: "الأسئلة الشائعة",
        products: "المنتجات",
        reviews: "المراجعات",
        sets: "أنواع المنتجات",
        stores: "المتاجر",
        city_id: "المدينة",
        brand_id: "العلامة التجارية",
        product_id: "المنتج",
        address_id: "العنوان",
        store_id: "المتجر",
        attributes_set_id: "نوع المنتج",
        attributes_ids: "الصفات",
        attribute_set_id: "الصفة",
        required: "مطلوب",
        searchable: "قابل للبحث",
        display_setting: "إعدادات العرض",
        default_options: "خيارات المنتج الإفتراضية",
        product_options: "خيارات المنتج",
        district_name: "المنطقة",
        building_name: "المبنى",
        building_type: "نوع المبنى",
        building_number: "المبنى",
        nearby_landmarks: "المعالم القريبة",
        language: "اللغة",
        language_code: "اللغة",
        store_code: "كود المتجر",
        key: "معرف الصفة",
        label: "اسم الصفة",
        quantity: "الكمية",
        image: "الصورة",
        thumbnail: "الصورة",
        icon: "الأيقونة",
        question: "السؤال",
        answer: "الإجابة",
        payment_id: "طريقة الدفع",
        payment_method: "طريقة الدفع",
        payment_type: "نوع الدفع",
        shipping_method_id: "طريقة الشحن",
        views: "الزيارات",
        reviewers: "المراجعين",
        stars: "التقييم",
        price: "السعر",
        total: "المجموع",
        special_price: "السعر الخاص",
        special_from: "خاص من",
        special_to: "خاص الى",
        color: "اللون",
        rate: "التقييم",
        confirmed: "مؤكد",
        unconfirmed: "غير مؤكد",
        categories_ids: "التصنيفات",
        "Product Status": "حالة المنتج",
        "Special Offer": "عرض خاص",
        "Description and Categories": "وصف وتصنيف المنتج",
        "Product Details": "تفاصيل المنتج",
        method_name: "طريقة الشحن",
        displayed_name: "الإسم المعروض",
        shippingMethods: "طرق الشحن",
        coupons: "عروض التخفيضات",
        coupon_code: "معرف العرض",
        discount: "قيمة العرض",
        order_amount: "قيمة الطلب",
        capacity: "سعة العرض",
        use_per_user: "معدل الإستخدام لكل مستخدم",
        usage: "معدل إستخدام العرض",
        date_from: "بداية العرض",
        date_to: "نهاية العرض",
        category_ids: "الفئة",
        sellers: "البائعين",
        Availability: "المواعيد المتاحة",
        "Available From": "متاح من",
        "Available To": "متاح إلي",
        paid: "مدفوع",
        countries: "الدول",
        cities: "المدن",
        "Sellers locations": "فروع البائعين",
        locations: "الفروع",
        seller: "البائع",
        coordinates: "الموقع علي الخريطة",
        items_ids: "عناصر الطلب",
        Product: "المنتج",
        Quantity: "الكمية",
        Price: "السعر",
        "Reserved From": "حجز من",
        "Reserved To": "حجز إلي",
        seller_id: "البائع",
        "Product Options": "خصائص المنتج",
        Stock: "الكميات المتاحة",
        Vendors: "الموزعون",
        "Group\\Event": "مجموعة/فعالية"
    }
}