const initialState = {
	loading: false,
	error: null,
	data: []
};

export default function reducer(state = initialState, action)
{
	if(action.type === "EXAMPLE_APP_LOADING")
		return {...state, isLoading: true, error: null};

	else if(action.type === "EXAMPLE_APP_SET_DATA")
		return {
			...state,
			isLoading: false,
			error: null,
			data: action.payload.type || []
		};

	return state;
}