import React, {lazy} from "react";
import {Route} from "react-router-dom";
import Reducer from "./reducer"

const List = lazy(() => import("./index"));
const Form = lazy(() => import("./form"));
const Show = lazy(() => import("./show"));

export default {
	reducers: {crud: Reducer},
	routes: [
		<Route key="crud_list" 		exact path="/:app/crud/:entity" 			component={(props) => <List key={props.location.pathname} {...props}/>} />,
		<Route key="crud_create" 	exact path="/:app/crud/:entity/create" 		component={(props) => <Form key={props.location.pathname} {...props}/>} />,
		<Route key="crud_show" 		exact path="/:app/crud/:entity/:id" 		component={(props) => <Show key={props.location.pathname} {...props}/>} />,
		<Route key="crud_edit" 		exact path="/:app/crud/:entity/:id/edit" 	component={(props) => <Form key={props.location.pathname} {...props}/>} />,
		<Route key="crud_list" 		exact path="/personal/:entity" 				component={(props) => <List key={props.location.pathname} {...props} mineOnly app="hr" />} />,
	],
	i18n: {
		en: {
			id: "ID",
			name: "Name",
			country_code: "Country Code",
			created_at: "Created At",
			updated_at: "Last Updated",
			country_id: "Country",
			full_name: "Full Name",
			status: "Status",
			email: "Email",
			password: "Password",
			contract_type: "Contract Type",
			from_date: "From Date",
			to_date: "To Date",
			from: "From",
			to: "To",
			address: "Address",
			amount: "Amount",
			notes: "Notes",
			start: "Start",
			end: "End",
			start_time: "Start Time",
			end_time: "End Time",
			action: "Action",
			value: "Value",
			period: "Period",
			parent_id: "Parent",
			description: "Description",
			roles_ids: "Permissions Role",
			role_id: "Role"
		},
		ar: {
			id: "الرقم التعريفي",
			name: "الإسم",
			country_code: "كود الدولة",
			created_at: "تاريخ الإضافة",
			updated_at: "أخر تحديث",
			country_id: "الدولة",
			full_name: "الإسم الكامل",
			email: "البريد الإلكتروني",
			password: "كلمة السر",
			status: "الحالة",
			contract_type: "نوع التعاقد",
			"Main Information": "المعلومات الأساسية",
			"Add New": "إضافة جديد",
			"Export Excel": "تصدير إكسل",
			"Print": "طباعة",
			"More": "المزيد",
			"All Information": "التفاصيل",
			"Edit Information": "تعديل المعلومات",
			"Delete this entity": "حدف السجل",
			Save: "حفظ",
			View: "معاينة",
			Delete: "حذف",
			from_date: "من تاريخ",
			to_date: "حتي تاريخ",
			from: "من",
			to: "إلي",
			address: "العنوان",
			amount: "القيمة",
			notes: "ملاحظات",
			start: "يبدا",
			"Filter Results": "فرز النتائج",
			"Show All": "الكل",
			Continue: "المتابعة",
			Cancel: "إلغاء",
			end: "ينتهي",
			start_time: "توفيت البداية",
			end_time: "توقيت النهاية",
			action: "الحدث",
			value: "القيمة",
			period: "الفترة",
			active: "فعال",
			disabled: "معطل",
			"in-review": "المراجعة",
			canceled: "ملغاة",
			approved: "مقبولة",
			pending: "قيد الانتظار",
			reject: "مرفوض",
			in: "دخول",
			out: "خروج",
			"Validation Error": "برجاء مراجعة البيانات المدخلة",
			Search: "بحث",
			code: "الرمز",
			parent_id: "فرعي من",
			Select: "إختيار",
			description: "الوصف",
			"Changes have been saved successfully": "تم حفظ التغيرات بنجاح",
			"Join date from": "تاريخ الإنضمام بداية من",
			"Join date to": "تاريخ الإنضمام حتي",
			roles: "الأدوار والصلاحيات",
			permissions_ids: "الصلاحيات",
			system: "النظام",
			username: "إسم المستخدم",
			roles_ids: "صلاحيات الوصول",
			role_id: "الدور",
			permissions: "الصلاحيات",
			Arabic: "العربية",
			media: "الصور الإضافية",
			Edit: "تعديل",
			branch_id: "الفرع",
			type: "النوع",
			"menu-click": "الضغط علي القائمة",
			From: "من",
			To: "إلي"
		}
	},
	navigation: {
		menu: []
	}
}

