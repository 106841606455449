import React, {lazy} from "react";
import {Redirect, Route} from "react-router-dom";


const Bulk  = lazy(() => import("./Bulk"));

export default {
	reducers: {},
	routes: [
		<Route key="notifications" exact path="/notifications" component={() => <Redirect to="/notifications/bulk" />} />,
		<Route key="notifications_bulk" exact path="/notifications/bulk" component={Bulk} />,
	],
	i18n: {
		en: {},
		ar: {}
	},
	navigation: {
		menu: [
		]
	}
}

