import React, {useEffect, useRef, Fragment} from 'react';
import {Rect, Circle, Arc, Wedge, RegularPolygon, Text, Transformer, Group} from "react-konva";

export default function Shape({ group, selected, onSelect, onChange })
{
    // shared props
    const props = group.attrs;
    const groupRef = useRef();
    const trRef = useRef();
    const isSelected = selected && !selected.seats && selected.id == props.id;

    //
    useEffect(() => {
        if (isSelected) {
            trRef.current.nodes([groupRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [isSelected]);

    //
    props.ref = groupRef;
    props.draggable = !selected || !selected.seats;
    props.onDblClick = (e) => onSelect(e, true);
    props.onClick = (e) => {
        if(!selected || !selected.seats) {
            onSelect(e);
        }
    }
    props.onDragEnd = (e) => {
        if(e.target.attrs.id === props.id) {
            onChange({...props, x: e.target.x(), y: e.target.y()});
        }
    }

    let groupShape = group.children.length ? group.children[0].attrs : null;

    props.onTransformEnd = (e) => {
        const node = groupRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        if(groupShape.type !== "Text") {
            node.scaleX(1);
            node.scaleY(1);
        }

        if(groupShape) {
            if(groupShape.type === "Rect") {
                groupShape.width = Math.max(5, groupShape.width * scaleX);
                groupShape.height = Math.max(groupShape.height * scaleY);
            }
            else if(["Circle", "Wedge", "RegularPolygon"].includes(groupShape.type)) {
                groupShape.radius = Math.max(groupShape.radius * scaleY);
            }
            else if(groupShape.type === "Arc") {
                groupShape.innerRadius = Math.max(groupShape.innerRadius * scaleY);
                groupShape.outerRadius = Math.max(groupShape.outerRadius * scaleY);
            }
        }

        onChange({...props, x: node.x(), y: node.y(), rotation: node.rotation()});
    };

    return (
        <Fragment>
            <Group {...props}>
                {group.children.map((child, i) => {
                    const Tag = child.className;
                    if(child.attrs.type !== "row") {
                        return <Tag key={i} {...child.attrs} />;
                    }

                    const {seatsCount, seatSize, space, curve} = child.attrs;
                    const p0x = 0;
                    const p1x = curve;
                    const p2x = curve;
                    const p3x = 0;

                    const onRowClick = () => {
                        if(selected && selected.seats)
                            onSelect(selected, true, i);
                    }

                    return (
                        <Fragment>
                            <Tag key={i} {...child.attrs}  rotation={child.attrs.rowRotation} onClick={onRowClick} draggable onDragEnd={(e) => {
                                child.attrs.x = e.target.x();
                                child.attrs.y = e.target.y();
                                onChange({...props});
                            }}>
                                {[...Array(seatsCount)].map((_, s) => {
                                    const t = (s + .5) / seatsCount;
                                    const y = Math.pow(1 - t, 3) * p0x + 3 * Math.pow(1 - t, 2) * t * p1x + 3 * (1 - t) * Math.pow(t, 2) * p2x + Math.pow(t, 3) * p3x;
                                    const sID = `g${props.section_id || ''}-r${child.attrs.rowID || ''}-s${(s+1)}`;
                                    return <Circle id={sID} key={s} type="seat" x={(s * space) + seatSize} y={y} radius={seatSize} fill={child.attrs.seatsColor || "red"} shadowBlur={(selected || {}).row === i ? 2 : 0} />
                                })}
                                <Text text={child.attrs.rowID || "?"} x="-5" y="-1.5" fontSize="3.5" />
                            </Tag>
                        </Fragment>
                    );
                })}
            </Group>

            { /* fixme: keepRatio not working */ }
            {isSelected && (
                <Transformer
                    ref={trRef}
                    boundBoxFunc={(oldBox, newBox) => ((newBox.width < 5 || newBox.height < 5) ? oldBox : newBox)}
                    keepRatio={groupShape && groupShape.type === "Circle"}
                />
            )}
        </Fragment>
    );
};
